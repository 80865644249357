<script>
export default {
  name: "NotificationComponent",
};
</script>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const state = store.state.notification;

store.dispatch("notification/unread").catch((error) => {
  console.log(error);
});

const notifications = computed(() => state.notifications);
</script>

<template>
  <b-container class="pt-4">
    <b-row class="justify-content-center justify-content-md-center">
      <b-cols><h1>Thông báo</h1></b-cols>
    </b-row>
    <b-row v-for="(notification, index) in notifications" :key="index" class="my-row">
      <b-col class="my-item" cols="8" :style="notification.unread ? 'color:red' : ''">
        <div v-html="notification.content"></div>
      </b-col>
      <b-col class="my-item" :style="notification.unread ? 'color:red' : ''">
        {{ notification.create_date }}
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.my-item {
  word-wrap: break-word;
  border: 1px solid #ddd;
  padding: 20px;
}
</style>
