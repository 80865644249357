<script>
export default {
  name: "CalculateComponent",
  components: {
    QuillEditor,
  },
};
</script>

<script setup>
// import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useCalculateStore } from "@/stores/calculate.module";
import { useCommonStore } from "@/stores/common.module";
import { QuillEditor } from "@vueup/vue-quill";
import Quill from "quill";

import "@vueup/vue-quill/dist/vue-quill.snow.css";
// import Delta from "quill-delta";
import _ from "lodash";
// import Quill from "quill";
import PlainClipboard from "../modules/VueClipBoard";
// import Delta from "quill-delta";

const store = useCalculateStore();
const commonStore = useCommonStore();

const authStore = useStore();

const route = useRoute();

const calc_type = authStore.state.auth.user.calculation_type;

store.$state.loading = true;
store
  .init()
  .then(() => {
    if (route.params.id) {
      store.$state.id = route.params.id;
      store.fetch(route.params.id).catch((error) => {
        console.log(error);
        commonStore.err("Có lỗi trong quá trình xử lý");
      });
    }
  })
  .catch((error) => {
    console.log(error);
    commonStore.err("Có lỗi trong quá trình xử lý");
  })
  .finally(() => {
    store.$state.loading = false;
  });

function calculate() {
  store.calculate().catch((err) => {
    commonStore.err("Có lỗi trong quá trình xử lý");
  });
}

Quill.register("modules/clipboard", PlainClipboard, true);
// Quill.register("modules/lineNumber", LineNumber, true);

// const modules = {
//   name: "modules/lineNumber",
//   module: LineNumber,
//   options: {
//     container: "#lineNumber",
//     /* options */
//   },
// };
// new Quill(".ql-editor", {
//   theme: "snow",
//   modules: {
//     lineNumber: {
//       container: "#line-numbers",
//     },
//   },
// });
</script>

<template>
  <b-container class="pt-3">
    <b-row class="justify-content-center justify-content-md-center">
      <b-cols><h1>Tính tiền</h1></b-cols>
    </b-row>
    <b-row class="pt-3">
      <b-col>
        <b-form-group id="fieldset-1" label="Ngày tính tiền" label-for="date">
          <b-form-datepicker id="date" v-model="store.$state.date"></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>

    <!--customers-->
    <b-row>
      <b-col>
        <b-form-group id="fieldset-1" label="Khách hàng" label-for="customers">
          <b-form-select
            id="customers"
            v-model="store.$state.selectedCustomer"
            :options="store.$state.customers"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <!--miền-->
    <b-row>
      <b-col>
        <b-form-group id="fieldset-1" label="Miền" label-for="region">
          <b-form-radio-group
            id="region"
            v-model="store.selectedRegion"
            :options="store.region"
            class="mb-3"
            value-field="value"
            text-field="text"
            disabled-field="notEnabled"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!--message-->
    <b-row>
      <b-col>
        <b-form-group id="fieldset-1" label="Tin nhắn" label-for="message">
          <QuillEditor theme="snow" v-model:content="store.quillContent" contentType="delta" id="editor" />
        </b-form-group>
      </b-col>
    </b-row>

    <!--button-->
    <b-row class="pt-3">
      <b-col>
        <b-button block variant="primary" @click.prevent="calculate" :disabled="store.disabled">
          <span v-show="store.loading" class="spinner-border spinner-border-sm" />
          <span>Tính tiền</span>
        </b-button>
      </b-col>
    </b-row>

    <!--mt result-->
    <b-row class="pt-3" v-if="calc_type == 'mt' && !_.isEmpty(store.result)">
      <b-col>
        <p v-if="store.result.haiso > 0" class="two-digits-bold">2 số :{{ store.result.haiso }}</p>
        <p v-if="store.result.baso > 0" class="three-digits-bold">3 số :{{ store.result.baso }}</p>
        <p v-if="store.result.bonso > 0" class="three-digits-bold">4 số :{{ store.result.bonso }}</p>
        <p v-if="store.result.dd > 0" class="two-digits-bold">Đầu đuôi :{{ store.result.dd }}</p>
        <p v-if="store.result.xc > 0" class="three-digits-bold">Xỉu chủ :{{ store.result.xc }}</p>
        <p v-if="store.result.dt > 0" class="two-digits-bold">Đá thẳng :{{ store.result.dt }}</p>
        <p v-if="store.result.dc > 0" class="two-digits-bold">Đá chéo :{{ store.result.dc }}</p>
      </b-col>
    </b-row>

    <!--mn result-->
    <b-row class="pt-3" v-if="calc_type == 'tp' && !_.isEmpty(store.result)">
      <b-col>
        <dl v-if="store.result.sum_2digits > 0">
          <dt class="two-digits-bold">2 số :{{ store.result.sum_2digits }}</dt>
          <dd v-if="store.result.haiso > 0">- Hai số: {{ store.result.haiso }}</dd>
          <dd v-if="store.result.dd > 0">- Đầu đuôi: {{ store.result.dd }}</dd>
          <dd v-if="store.result.dt > 0">- Đá thẳng: {{ store.result.dt }}</dd>
          <dd v-if="store.result.dc > 0">- Đá chéo: {{ store.result.dc }}</dd>
        </dl>
        <dl v-if="store.result.sum_3digits > 0">
          <dt class="three-digits-bold">3 số :{{ store.result.sum_3digits }}</dt>
          <dd v-if="store.result.baso > 0">- Ba số: {{ store.result.baso }}</dd>
          <dd v-if="store.result.bonso > 0">- Bốn số: {{ store.result.bonso }}</dd>
          <dd v-if="store.result.xc > 0">- Xỉu chủ: {{ store.result.xc }}</dd>
        </dl>
      </b-col>
    </b-row>

    <!--Previous Message-->
    <b-row class="pt-3" v-if="store.prevMessage && store.prevMessage.length > 0">
      <b-col>
        Kết quả:
        <p style="white-space: break-spaces">{{ store.prevMessage }}</p>
      </b-col>
    </b-row>

    <!--errors-->
    <b-row class="pt-3">
      <b-col>
        <p v-for="(error, index) in store.errors" :key="index" style="color: red">
          {{ error }}
        </p>
      </b-col>
    </b-row>
    <b-row class="pt-3">
      <b-col>
        <b-table striped hover :items="store.resultItems.items" :fields="store.resultItems.fields"></b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.ql-toolbar {
  display: none;
}
.ql-container {
  border-top: 1px solid #ccc !important;
  height: 250px;
  max-height: 250px;
  /* overflow: auto; */
  overflow-y: visible !important;
  font-size: 18px;
}

.ql-editor {
  counter-reset: line;
  padding-left: 0;
}

.ql-editor p {
  position: relative;
}

.ql-editor p:before {
  counter-increment: line;
  content: counter(line);
  display: inline-block;
  border-right: 1px solid #ddd;
  padding: 0 0.5em;
  margin-right: 0.5em;
  color: #888;
  height: 100%;
  line-height: inherit;
  vertical-align: middle;
}
</style>
