import { defineStore } from "pinia";
import customerService from "@/services/customer.service";
import { useCommonStore } from "./common.module";
import { empty_customer } from "@/utils/constants";
import _ from "lodash";

const validate_number_type_fields = [
  "haiso",
  "baso",
  "bonso",
  "dd",
  "xc",
  "dt",
  "dc",
  "trunghaiso",
  "trungbaso",
  "trungbonso",
  "trungdd",
  "trungxc",
  "trungdt",
  "trungdc",
];

const validate_profit_type_0_fields = ["lai2s", "lai3s", "lai4s", "laixc", "laidd", "laidt", "laidc"];

const validate_profit_type_1_fields = ["lai_all"];

const validate_profit_type_2_fields = ["lai_2digits", "lai_3digits"];

const empty_errors = {
  name: "",
  customersNumberSettings: {
    0: {},
    1: {},
    2: {},
  },
};
export const useCustomerFormStore = defineStore("customerForm", {
  state: () => {
    return {
      customer: {},
      errors: {
        name: "",
        customersNumberSettings: {
          0: {},
          1: {},
          2: {},
        },
      },
      btnText: "",
      loading: false,
      isError: false,
    };
  },

  getters: {
    getErrors(index, field) {
      return _.get(this.errors, `customersNumberSettings.${index}.${field}`);
    },
  },

  actions: {
    async get(id) {
      this.btnText = id ? "Cập nhật" : "Tạo";
      if (!id) {
        this.customer = empty_customer;
        return;
      }

      const commonStore = useCommonStore();
      this.loading = true;
      return customerService
        .get(id)
        .then((res) => {
          console.log(res.data);
          let customer = res.data;
          this.customer = customer;
          console.log("get completed");
        })
        .catch((error) => {
          commonStore.err("Có lỗi xảy ra, xin hãy thử lại sau!");
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },

    async validate() {
      const commonStore = useCommonStore();
      this.isError = false;
      this.errors = _.cloneDeep(empty_errors);

      if (this.customer.name === "") {
        this.errors.name = "Xin hãy nhập tên khách hàng";
        this.isError = true;
        return;
      }

      const settings = this.customer.customersNumberSettings;
      for (const key in settings) {
        for (const fieldIdx in validate_number_type_fields) {
          const field = validate_number_type_fields[fieldIdx];

          const error = this.validateNumber(settings[key][field]);

          if (error) {
            _.set(this.errors, `customersNumberSettings.${key}.${field}`, error);
            this.isError = true;
          }
        }

        if (this.customer.commission_calculation_type != "none") {
          var validate_fields;
          switch (this.customer.profit_type) {
            case "0":
              validate_fields = validate_profit_type_0_fields;
              break;
            case "1":
              validate_fields = validate_profit_type_1_fields;
              break;
            case "2":
              validate_fields = validate_profit_type_2_fields;
              break;
          }

          for (const fieldIdx in validate_fields) {
            const field = validate_fields[fieldIdx];
            const error = this.validateNumber(settings[key][field]);

            if (error) {
              _.set(this.errors, `customersNumberSettings.${key}.${field}`, error);
              this.isError = true;
            }
          }
        }
      }
      if (this.isError) {
        commonStore.warning("Dữ liệu không hợp lệ, xin hãy kiểm tra lại");
      }
    },

    validateNumber(val) {
      if (val === "" || isNaN(val)) {
        return "Xin hãy nhập số";
      }
      const num = parseFloat(val);
      if (num < 0 || num > 100000) {
        return `Xin hãy nhập số nằm trong khoảng 0 ~ 100,000`;
      }
      return "";
    },

    async create() {
      const customer = this.customer;
      const commonStore = useCommonStore();

      if (customer.name === "") {
        commonStore.err("Xin hãy nhập tên khách hàng");
        return Promise.resolve();
      }
      try {
        try {
          await customerService.create(customer);
          commonStore.success("Tạo thông tin khách hàng thành công!");
        } catch (error) {
          console.log(error);
          commonStore.err("Có lỗi xảy ra, xin hãy thử lại sau!");
        }
      } finally {
        commonStore.setLoading(false);
      }
    },
    async update() {
      const customer = this.customer;
      const commonStore = useCommonStore();
      if (customer.name === "") {
        commonStore.err("Xin hãy nhập tên khách hàng");
        return Promise.resolve();
      }

      commonStore.setLoading(true);
      try {
        try {
          await customerService.update(customer);
          commonStore.success("Cập nhật thông tin khách hàng thành công!");
        } catch {
          commonStore.err("Có lỗi xảy ra, xin hãy thử lại sau!");
        }
      } finally {
        commonStore.setLoading(false);
      }
    },
  },
});
