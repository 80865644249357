// stores/counter.js
import { defineStore } from "pinia";
import provinceService from "@/services/province.service";
// import numbertypeService from "@/services/numbertype.service";
import { useCommonStore } from "./common.module";
import _ from "lodash";
import userService from "@/services/user.service";
import numbertypeService from "@/services/numbertype.service";

export const WEEKDAY_TITLE = {
  0: "2,3,4 đài",
  1: "Chủ nhật",
  2: "Thứ 2",
  3: "Thứ 3",
  4: "Thứ 4",
  5: "Thứ 5",
  6: "Thứ 6",
  7: "Thứ 7",
};

export const REGION_TITLE = {
  mt: "Miền Trung",
  mn: "Miền Nam",
  mb: "Miền Bắc",
};

export const useSyntaxStore = defineStore("syntax", {
  state: () => {
    return {
      provinces: {},

      displayProvinces: {},
      changedProvinces: {},

      numbertypes: {},
      displayNumbertypes: {},
      changedNumbertypes: {},

      errors: {},

      loading: false,
      posting: false,
    };
  },

  getters: {},
  actions: {
    async fetch() {
      this.displayProvinces = {};
      this.changedProvinces = {};
      this.isEnable = true;

      const syntaxData = await userService.getSyntax().then((res) => res.data.data);

      this.fetchProvinces(syntaxData.provinces);
      this.fetchNumbertypes(syntaxData.numbertypes);

      return Promise.resolve();
    },

    fetchProvinces(provinces) {
      this.provinces = provinces;

      const displayProvinces = {};

      _.each(this.provinces, (province) => {
        const name = _.get(displayProvinces, [province.region, province.weekday, province.province_index], []);
        const nameArray = _.isEmpty(name) ? [] : _.split(name, ",");
        nameArray.push(province.name);

        _.setWith(
          displayProvinces,
          `${province.region}.${province.weekday}.${province.province_index}`,
          _.join(nameArray, ","),
          Object
        );
      });

      this.displayProvinces = displayProvinces;
    },
    fetchNumbertypes(numbertypes) {
      this.numbertypes = numbertypes;

      const displayNumbertypes = {};
      _.each(this.numbertypes, (numbertype) => {
        const type = _.get(displayNumbertypes, [numbertype.numbertypeId], []);
        const typeArray = _.isEmpty(type) ? [] : _.split(type, ",");
        typeArray.push(numbertype.type);

        _.setWith(displayNumbertypes, [numbertype.numbertypeId], _.join(typeArray, ","), Object);
      });

      this.displayNumbertypes = displayNumbertypes;
    },
    onChangeProvince(region, weekday, provinceIndex) {
      const val = _.get(this.displayProvinces, [region, weekday, provinceIndex], "");
      _.setWith(this.changedProvinces, `${region}.${weekday}.${provinceIndex}`, val, Object);
    },

    saveProvince() {
      const commonStore = useCommonStore();
      this.posting = true;
      this.errors = {};

      let checkDuplication = {};
      let data = [];

      _.each(this.changedProvinces, (region, regionKey) => {
        _.each(region, (weekday, weekdayKey) => {
          _.each(weekday, (provinces, province_index) => {
            _.each(_.filter(_.split(provinces, ","), "length"), (province) => {
              let provinceToCheck = province.trim();

              const reg = /^[0-9A-Za-z]+$/;
              if (!reg.test(provinceToCheck)) {
                _.setWith(
                  this.errors,
                  `${regionKey}.${weekdayKey}.${province_index}`,
                  `Sai cú pháp: ${provinceToCheck}<br/>
                  Tên đài chỉ được chứa ký tự không dấu và số`,
                  Object
                );
                return;
              }

              if (_.has(checkDuplication, provinceToCheck)) {
                _.setWith(
                  this.errors,
                  `${regionKey}.${weekdayKey}.${province_index}`,
                  `Đài ${provinceToCheck} trùng nhau`,
                  Object
                );
                _.setWith(this.errors, checkDuplication[provinceToCheck], `Đài ${provinceToCheck} trùng nhau`, Object);
                return;
              } else {
                checkDuplication[provinceToCheck] = `${regionKey}.${weekdayKey}.${province_index}`;
              }

              data.push({
                region: regionKey,
                weekday: weekdayKey,
                province_index: province_index,
                name: provinceToCheck,
              });
            });

            const originalData = _.filter(this.provinces, (province) => {
              return (
                province.region == regionKey &&
                province.weekday == weekdayKey &&
                province.province_index == province_index
              );
            });

            _.each(originalData, (province) => {
              const index = _.findIndex(data, (item) => {
                return (
                  item.region == regionKey &&
                  item.weekday == weekdayKey &&
                  item.province_index == province_index &&
                  item.name == province.name
                );
              });
              if (index < 0) {
                data.push({
                  id: province.id,
                  is_delete: true,
                });
              } else {
                _.remove(data, (x, idx) => {
                  return idx == index;
                });
              }
            });
          });
        });
      });

      if (!_.isEmpty(this.errors)) {
        commonStore.err("Lưu không thành công, xin hãy kiểm tra lại các đài");
        this.posting = false;
        return;
      }

      return provinceService
        .create({ data: data })
        .then((res) => {
          const data = res.data.data;

          this.fetchProvinces(data.provinces);
          this.fetchNumbertypes(data.numbertypes);

          commonStore.success("Cập nhật thành công");
        })
        .catch(() => {
          commonStore.err("Có lỗi trong quá trình cập nhật");
        })
        .finally(() => {
          this.posting = false;
        });
    },

    onChangeNumbertypes(numbertypeId) {
      const val = _.get(this.displayNumbertypes, [numbertypeId], "");
      _.setWith(this.changedNumbertypes, [numbertypeId], val, Object);
    },
    saveNumbertypes() {
      const commonStore = useCommonStore();
      this.posting = true;
      this.errors = {};

      const checkDuplication = {};
      const reg = /^[A-Za-z]+$/;

      let data = [];
      _.each(this.changedNumbertypes, (numbertypes, numbertypeId) => {
        const filteredEmpty = _.filter(_.split(numbertypes, ","), "length");
        let tmpData = [];

        _.each(filteredEmpty, (numbertype) => {
          const numbertypeToCheck = _.trim(numbertype);

          if (!reg.test(numbertypeToCheck)) {
            this.setError(
              [numbertypeId],
              `
            Sai cú pháp ${numbertypeToCheck}. Xin quý khách vui lòng chỉ nhập ký tự tiếng anh không dấu, và không nhập số`
            );
            return;
          }

          if (_.has(checkDuplication, numbertypeToCheck)) {
            this.setError([numbertypeId], `Cú pháp ${numbertypeToCheck} trùng nhau`);

            this.setError(checkDuplication[numbertypeToCheck], `Cú pháp ${numbertypeToCheck} trùng nhau`);
            return;
          } else {
            checkDuplication[numbertypeToCheck] = numbertypeId;
          }

          const duplicateNumbertypeIndex = _.findIndex(
            this.numbertypes,
            (x) => x.type == numbertypeToCheck && x.numbertypeId != numbertypeId
          );
          if (duplicateNumbertypeIndex > -1) {
            this.setError([numbertypeId], `Cú pháp ${numbertypeToCheck} trùng nhau`);
            this.setError(
              this.numbertypes[duplicateNumbertypeIndex].numbertypeId,
              `Cú pháp ${numbertypeToCheck} trùng nhau`
            );
          }

          tmpData.push({
            type: numbertypeToCheck,
            numbertypeId: numbertypeId,
          });
        });

        const originalNumbertypes = _.filter(this.numbertypes, (x) => x.numbertypeId == numbertypeId);
        const xor = _.xorBy(originalNumbertypes, tmpData, "type");

        data = [...xor];
      });

      if (!_.isEmpty(this.errors)) {
        commonStore.err("Lưu không thành công, xin hãy kiểm tra lại các cú pháp");
        this.posting = false;
        return;
      }

      return numbertypeService
        .create({ data: data })
        .then((res) => {
          const data = res.data.data;

          this.fetchNumbertypes(data.numbertypes);
          this.fetchProvinces(data.provinces);

          commonStore.success("Cập nhật thành công");
        })
        .finally(() => {
          this.posting = false;
        });
    },

    setError(key, message) {
      _.setWith(this.errors, key, message, Object);
    },
  },
});
