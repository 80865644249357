<script>
export default {
  name: "ManagerComponent",
};
</script>

<script setup>
// import { computed } from "vue";
import { useStore } from "vuex";
// import { useRoute } from "vue-router";
// import _ from "lodash";

const store = useStore();
const state = store.state.manager;
store.dispatch("manager/fetchUsers");

function onRowSelected(items) {
  store.commit("manager/setSelected", items);
}
function update() {
  store
    .dispatch("manager/update")
    .then((res) => {
      if (!res.data.success) {
        store.commit("manager/setErrors", res.data.errors);
      } else {
        store.dispatch("manager/fetchUsers");
      }
    })
    .catch(() => store.commit("error", "Có lỗi trong quá trình xử lý", { root: true }));
}
</script>

<template>
  <b-container class="pt-3">
    <b-row class="justify-content-center justify-content-md-center">
      <b-cols><h1>Manager</h1></b-cols>
    </b-row>

    <b-row>
      <b-col>
        <b-button block variant="primary" @click.prevent="update">Update</b-button>
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <b-form-textarea rows="5" v-model="state.selectedUsername"></b-form-textarea>
        {{ state.errors }}
      </b-col>
    </b-row>
    <b-row class="pt-5">
      <b-col>
        <b-table
          :items="state.users"
          :fields="store.getters['manager/fields']"
          select="multi"
          responsive="sm"
          ref="selectableTable"
          selectable
          @row-selected="onRowSelected"
        >
          <!-- Example scoped slot for select state illustrative purposes -->
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<style></style>
