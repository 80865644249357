import { defineStore } from "pinia";
import moment from "moment";
import Delta from "quill-delta";
import customerService from "@/services/customer.service";
import messageService from "@/services/message.service";
import _ from "lodash";
import { useCommonStore } from "./common.module";

export const useCalculateStore = defineStore("calculate", {
  state: () => {
    return {
      selectedCustomer: "",
      customers: [],
      date: moment(new Date()).format("YYYY-MM-DD"),
      loading: false,
      selectedRegion: "mn",
      prevMessage: "",
      quillContent: new Delta(),
      result: {},
      id: "",
      region: [
        { text: "Miền Nam", value: "mn" },
        { text: "Miền Trung", value: "mt" },
        { text: "Miền Bắc", value: "mb" },
      ],
      errors: [],
      error_patterns: [{ text: /###/g }],
    };
  },
  getters: {
    disabled() {
      if (this.selectedCustomer === "") return false;
      if (this.date === null) return true;
      if (this.selectedRegion === null) return true;
      if (this.loading) return true;
      if (this.message.replace("\n", "") == "") return true;

      return false;
    },
    message: (state) => {
      return _.reduce(
        state.quillContent.ops,
        function (result, x) {
          console.log(result);
          return result + x.insert;
        },
        ""
      );
    },
    resultItems() {
      const fields = [
        { key: "originalProvinceText", label: "Đài" },
        { key: "number", label: "Số" },
        { key: "originalType", label: "Kiểu đánh" },
        { key: "realmoney", label: "Tiền" },
      ];
      const items = _.map(this.result.messagedetails, function (value) {
        return {
          originalProvinceText: value.originalProvinceText,
          number: value.number,
          originalType: value.originalType,
          realmoney: value.realmoney,
        };
      });
      return {
        fields,
        items,
      };
    },
  },
  actions: {
    init() {
      return customerService.fetch().then((res) => {
        const commonStore = useCommonStore();
        if (!_.isEmpty(res.data)) {
          this.customers = res.data;
          this.selectedCustomer = res.data[0].id;
        } else {
          commonStore.err("Chưa có thông tin khách hàng, xin hãy thêm thông tin khách hàng");
        }
      });
    },
    calculate() {
      this.loading = true;
      this.prevMessage = "";
      this.errors = [];
      this.result = {};
      const commonStore = useCommonStore();

      const data = {
        crDate: this.date,
        customer_id: this.selectedCustomer,
        mien: this.selectedRegion,
        message: this.message,
      };

      if (this.id) {
        data.id = this.id;
      }
      return messageService
        .calculate(data)
        .then((res) => {
          if (!res.data.success) {
            this.errors = res.data.errors;

            const reg = new RegExp(`${res.data.error_patterns}`, "gi");
            const splitted = _.compact(this.message.split(reg));

            const delta = new Delta(
              _.map(splitted, function (x) {
                return {
                  insert: x,
                  attributes: {
                    background: x.match(reg) ? "#ffff00" : "",
                  },
                };
              })
            );

            this.quillContent = delta;
          } else {
            this.prevMessage = this.message;
            this.quillContent = new Delta();
            this.result = res.data.data;
            this.id = "";
            commonStore.success("Tính tiền thành công");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetch() {
      return messageService.get(this.id).then((res) => {
        this.quillContent = new Delta([{ insert: res.data.message }]);
        this.selectedCustomer = res.data.customer_id;
        this.date = moment(res.data.crDate, "YYYYMMDD").format("YYYY-MM-DD");
        this.selectedRegion = res.data.mien;
      });
    },
  },
});
