<script>
export default {
  name: "PasswordComponent",
};
</script>

<script setup>
// import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const state = store.state.password;

function update() {
  store
    .dispatch("password/update", state)
    .then(() => store.dispatch("success", "Cập nhật thành công"))
    .catch((err) => store.dispatch("error", err));
}

// store.dispatch("notification/unread");

// const notifications = computed(() => state.notifications);
</script>

<template>
  <b-container class="pt-5">
    <b-row class="justify-content-center justify-content-md-center">
      <b-cols><h1>Đổi mật khẩu</h1></b-cols>
    </b-row>
    <b-form-group label-cols="4" label-cols-lg="2" label="Mật khẩu mới" label-for="input-default">
      <b-form-input type="password" v-model="state.password"></b-form-input>
    </b-form-group>
    <b-form-group label-cols="4" label-cols-lg="2" label="Nhập lại mật khẩu mới" label-for="input-default">
      <b-form-input type="password" v-model="state.confirmPassword"></b-form-input>
    </b-form-group>
    <b-row>
      <b-col md="4" offset-md="4" class="pb-2">
        <b-button type="submit" variant="primary" block :disabled="state.loading" @click.prevent="update">
          <span v-show="state.loading" class="spinner-border spinner-border-sm" />
          <span>Đổi mật khẩu</span>
        </b-button>
      </b-col>
      <!-- <b-col lg="4" class="pb-2"><b-button>Default Button</b-button></b-col>
      <b-col lg="4" class="pb-2"><b-button size="lg">Large Button</b-button></b-col> -->
    </b-row>
  </b-container>
</template>

<style>
.my-item {
  word-wrap: break-word;
  border: 1px solid #ddd;
  padding: 20px;
}
</style>
