<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  calc_type: String,
  messageSummary: Object,
});
const showTotal2Digits = computed(() => {
  return props.messageSummary.money_2s != 0 || props.messageSummary.win_2s != 0 || props.messageSummary.profit_2s != 0;
});
const showTotal3Digits = computed(() => {
  return props.messageSummary.money_3s != 0 || props.messageSummary.win_3s != 0 || props.messageSummary.profit_3s != 0;
});
const showTotal4Digits = computed(() => {
  return props.messageSummary.money_4s != 0 || props.messageSummary.win_4s != 0 || props.messageSummary.profit_4s != 0;
});
const showTotalDd = computed(() => {
  return props.messageSummary.money_dd != 0 || props.messageSummary.win_dd != 0 || props.messageSummary.profit_dd != 0;
});
const showTotalXc = computed(() => {
  return props.messageSummary.money_xc != 0 || props.messageSummary.win_xc != 0 || props.messageSummary.profit_xc != 0;
});
const showTotalDt = computed(() => {
  return props.messageSummary.money_dt != 0 || props.messageSummary.win_dt != 0 || props.messageSummary.profit_dt != 0;
});
const showTotalDc = computed(() => {
  return props.messageSummary.money_dc != 0 || props.messageSummary.win_dc != 0 || props.messageSummary.profit_dc != 0;
});
const showGrandTotal2Digits = computed(() => {
  return (
    props.messageSummary.sum_money_2digits != 0 ||
    props.messageSummary.win_2digits != 0 ||
    props.messageSummary.profit_2digits != 0
  );
});
const showGrandTotal3Digits = computed(() => {
  return (
    props.messageSummary.sum_money_3digits != 0 ||
    props.messageSummary.win_3digits != 0 ||
    props.messageSummary.profit_3digits != 0
  );
});

const thuChiText = computed(() => {
  return props.messageSummary.profit < 0 ? "Thu" : "Chi";
});
</script>

<template>
  <table class="table table-bordered" style="text-align: center">
    <tr>
      <th></th>
      <th>Tổng tiền đánh</th>
      <th>Tổng tiền trúng</th>
      <th>Thu chi(trừ lai về)</th>
    </tr>
    <tr :class="calc_type == 'mt' ? '' : 'two-digits'" v-if="showTotal2Digits">
      <td>2 số</td>
      <td>{{ messageSummary.money_2s }}</td>
      <td>{{ messageSummary.win_2s }}</td>
      <td>
        {{ messageSummary.profit_2s }}
        <p v-if="messageSummary.profit_2s != messageSummary.commission_2s && messageSummary.csommission_2s != 0">
          ({{ messageSummary.commission_2s }})
        </p>
      </td>
    </tr>

    <tr v-if="showTotal3Digits">
      <td>3 số</td>
      <td>{{ messageSummary.money_3s }}</td>
      <td>{{ messageSummary.win_3s }}</td>
      <td>
        {{ messageSummary.profit_3s }}
        <p v-if="messageSummary.profit_3s != messageSummary.commission_3s && messageSummary.commission_3s != 0">
          ({{ messageSummary.commission_3s }})
        </p>
      </td>
    </tr>

    <tr v-if="showTotal4Digits">
      <td>4 số</td>
      <td>{{ messageSummary.money_4s }}</td>
      <td>{{ messageSummary.win_4s }}</td>
      <td>
        {{ messageSummary.profit_4s }}
        <p v-if="messageSummary.profit_4s != messageSummary.commission_4s && messageSummary.commission_4s != 0">
          ({{ messageSummary.commission_4s }})
        </p>
      </td>
    </tr>

    <tr v-if="showTotalDd" :class="calc_type == 'mt' ? '' : 'two-digits'">
      <td>Đầu đuôi</td>
      <td>{{ messageSummary.money_dd }}</td>
      <td>{{ messageSummary.win_dd }}</td>
      <td>
        {{ messageSummary.profit_dd }}
        <p v-if="messageSummary.profit_dd != messageSummary.commission_dd && messageSummary.commission_dd != 0">
          ({{ messageSummary.commission_dd }})
        </p>
      </td>
    </tr>

    <tr v-if="showTotalXc">
      <td>Xỉu chủ</td>
      <td>{{ messageSummary.money_xc }}</td>
      <td>{{ messageSummary.win_xc }}</td>
      <td>
        <span>{{ messageSummary.profit_xc }}</span>
        <p v-if="messageSummary.profit_xc != messageSummary.commission_xc && messageSummary.commission_xc != 0">
          ({{ messageSummary.commission_xc }})
        </p>
      </td>
    </tr>

    <tr v-if="showTotalDt" :class="calc_type == 'mt' ? '' : 'two-digits'">
      <td>Đá thẳng</td>
      <td>{{ messageSummary.money_dt }}</td>
      <td>{{ messageSummary.win_dt }}</td>
      <td>
        <span>{{ messageSummary.profit_dt }}</span>
        <p v-if="messageSummary.profit_dt != messageSummary.commission_dt && messageSummary.commission_dt != 0">
          ({{ messageSummary.commission_dt }})
        </p>
      </td>
    </tr>

    <tr v-if="showTotalDc" :class="calc_type == 'mt' ? '' : 'two-digits'">
      <td>Đá chéo</td>
      <td>{{ messageSummary.money_dc }}</td>
      <td>{{ messageSummary.win_dc }}</td>
      <td>
        {{ messageSummary.profit_dc }}
        <p v-if="messageSummary.profit_dc != messageSummary.commission_dc && messageSummary.commission_dc != 0">
          ({{ messageSummary.commission_dc }})
        </p>
      </td>
    </tr>

    <tr class="two-digits-bold" v-if="calc_type != 'mt' && showGrandTotal2Digits">
      <td>Tổng 2 số</td>
      <td>
        <span>{{ messageSummary.sum_money_2digits }}</span>
        <p
          v-if="
            messageSummary.sum_money_2digits != messageSummary.calculated_sum_money_2digits &&
            messageSummary.calculated_sum_money_2digits != 0
          "
        >
          ({{ messageSummary.calculated_sum_money_2digits }})
        </p>
      </td>
      <td>
        <span>{{ messageSummary.win_2digits }}</span>
      </td>
      <td>
        <span>{{ messageSummary.profit_2digits }}</span>
        <p
          v-if="
            messageSummary.profit_2digits != messageSummary.commission_2digits && messageSummary.commission_2digits != 0
          "
        >
          ({{ messageSummary.commission_2digits }})
        </p>
      </td>
    </tr>
    <tr class="three-digits-bold" v-if="calc_type != 'mt' && showGrandTotal3Digits">
      <td>Tổng 3 số</td>
      <td>
        <span>{{ messageSummary.sum_money_3digits }}</span>
        <p
          v-if="
            messageSummary.sum_money_3digits != messageSummary.calculated_sum_money_3digits &&
            messageSummary.calculated_sum_money_3digits != 0
          "
        >
          ({{ messageSummary.calculated_sum_money_3digits }})
        </p>
      </td>
      <td>
        {{ messageSummary.win_3digits }}
      </td>
      <td>
        <span>{{ messageSummary.profit_3digits }}</span>
        <p
          v-if="
            messageSummary.profit_3digits != messageSummary.commission_3digits && messageSummary.commission_3digits != 0
          "
        >
          ({{ messageSummary.commission_3digits }})
        </p>
      </td>
    </tr>
    <tr :style="calc_type == 'mt' ? 'font-weight:bold' : 'color: purple'">
      <td>Tổng Thu chi</td>
      <td>
        <span>{{ messageSummary.sum_money }}</span>
        <p
          v-if="
            messageSummary.sum_money != messageSummary.calculated_sum_money && messageSummary.calculated_sum_money != 0
          "
        >
          ({{ messageSummary.calculated_sum_money }})
        </p>
      </td>
      <td>
        <span>{{ messageSummary.win_money }}</span>
        <p
          v-if="
            messageSummary.win_money != messageSummary.calculated_win_money && messageSummary.calculated_win_money != 0
          "
        >
          ({{ messageSummary.calculated_win_money }})
        </p>
      </td>
      <td>
        <span>{{ thuChiText }} : {{ messageSummary.profit }}</span>
        <p v-if="messageSummary.profit != messageSummary.commission && messageSummary.commission != 0">
          ({{ messageSummary.commission }})
        </p>
      </td>
    </tr>
  </table>
</template>
