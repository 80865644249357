import client from "./http-common";

class NotificationService {
  getUnread() {
    return client.get("notice");
  }

  postReadAll() {
    return client.post("notice/read-all");
  }
}

export default new NotificationService();
