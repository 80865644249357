import userService from "@/services/user.service";

export const manager2 = {
  namespaced: true,
  state: {
    user: {
      username: "",
      password: "Abc12345",
      calculation_type: "tp",
      expired_date: "",
      groupname: "",
    },
  },

  actions: {
    create({ commit, state, dispatch }) {
      commit("loading", true, { root: true });

      return userService
        .create(state.user)
        .then(
          () => {
            dispatch("success", "Tạo thành công", { root: true });
          },
          (err) => {
            dispatch("error", "Tạo thất bại", { root: true });
            console.log(err);
          }
        )
        .finally(() => {
          commit("loading", false, { root: true });
        });
    },
  },
};
