<script>
export default {
  name: "store.$state.customersComponent",
};
</script>

<script setup>
import { useCustomerFormStore } from "@/stores/customer.form.module";
import {
  profit_values,
  sign_values,
  bonus_dt_type_values,
  region_text,
  commission_calculation_type_values,
  customer_allow_dc_2d,
} from "@/utils/constants";
import { useRoute } from "vue-router";
import _ from "lodash";

const store = useCustomerFormStore();
const route = useRoute();

const id = route.params.id;
store.get(id);
const customer = store.$state.customer;

const state = store.$state;

const createOrUpdate = async () => {
  await store.validate();
  if (store.$state.isError) {
    return;
  }

  if (id) {
    store.update();
  } else {
    store.create();
  }
};

const numbertypedetails = [
  {
    title: "Hai số",
    field: "haiso",
    winningField: "trunghaiso",
  },
  {
    title: "Ba số",
    field: "baso",
    winningField: "trungbaso",
  },
  {
    title: "Bốn số",
    field: "bonso",
    winningField: "trungbonso",
  },
  {
    title: "Đầu đuôi",
    field: "dd",
    winningField: "trungdd",
  },
  {
    title: "Xỉu chủ",
    field: "xc",
    winningField: "trungxc",
  },
  {
    title: "Đá thẳng",
    field: "dt",
    winningField: "trungdt",
  },
  {
    title: "Đá chéo",
    field: "dc",
    winningField: "trungdc",
  },
];

const profit_type_field_0 = [
  {
    title: "Lai hai số",
    field: "lai2s",
  },
  {
    title: "Lai ba số",
    field: "lai3s",
  },
  {
    title: "Lai bốn số",
    field: "lai4s",
  },
  {
    title: "Lai đầu đuôi",
    field: "laidd",
  },
  {
    title: "Lai xỉu chủ",
    field: "laixc",
  },
  {
    title: "Lai đá thẳng",
    field: "laidt",
  },
  {
    title: "Lai đá chéo",
    field: "laidc",
  },
];
</script>

<template>
  <b-container>
    <b-row class="justify-content-center justify-content-md-center">
      <b-cols><h1>Quản lý khách hàng</h1></b-cols>
    </b-row>
    <b-row style="margin-bottom: 20px">
      <b-form @submit.stop.prevent>
        <b-col>
          <table class="table table-condensed">
            <tr>
              <td class="title">Tên khách hàng</td>
              <td colspan="3">
                <b-form-input v-model="store.$state.customer.name" name="customerName"></b-form-input>
                <p class="danger" v-if="state.errors.name">{{ state.errors.name }}</p>
              </td>
            </tr>
            <tr>
              <td class="title">Kiểu tính tiền lai</td>
              <td colspan="3">
                <b-form-select
                  v-model="store.$state.customer.commission_calculation_type"
                  :options="commission_calculation_type_values"
                  name="commissionCalculationType"
                ></b-form-select>
              </td>
            </tr>
            <tr>
              <td class="title">Kiểu lai</td>
              <td colspan="3">
                <b-form-select
                  v-model="store.$state.customer.profit_type"
                  :options="profit_values"
                  name="profitType"
                ></b-form-select>
              </td>
            </tr>
            <tr>
              <td class="title">
                Cho phép cú pháp đá thẳng thành đá chéo 2 đài
                <button
                  v-b-tooltip.hover
                  title='Trường hợp để mặc định thì sẽ dùng cài đặt
                 "Cú pháp đá chéo từ 2 đài trở lên" ở phần Cài đặt. Trường hợp muốn chỉnh riêng cho từng khách hàng thì sẽ chỉnh sửa tại đây.'
                  class="tooltip-btn"
                >
                  <b-icon-question-circle></b-icon-question-circle>
                </button>
              </td>
              <td colspan="3">
                <b-form-select
                  v-model="store.$state.customer.allow_dc_2d"
                  :options="customer_allow_dc_2d"
                ></b-form-select>
              </td>
            </tr>
            <tr>
              <td class="title">Loại khách hàng</td>
              <td colspan="3">
                <b-form-radio-group
                  v-model="store.$state.customer.sign"
                  :options="sign_values"
                  class="mb-3"
                  name="sign"
                ></b-form-radio-group>
              </td>
            </tr>
            <tr>
              <td class="title">Thưởng đá thẳng miền Nam</td>
              <td colspan="3">
                <b-form-radio-group
                  v-model="store.$state.customer.bonus_dt_type"
                  :options="bonus_dt_type_values"
                ></b-form-radio-group>
              </td>
            </tr>
            <tr>
              <td class="title">Thưởng đá miền bắc</td>
              <td colspan="3">
                <b-form-radio-group
                  v-model="store.$state.customer.bonus_dt_type_mb"
                  :options="bonus_dt_type_values"
                ></b-form-radio-group>
              </td>
            </tr>
            <tr>
              <th>Miền</th>
              <th>Kiểu đánh</th>
              <th>Tiền đánh</th>
              <th>Tiền trúng</th>
            </tr>

            <template v-for="(setting, index) in store.$state.customer.customersNumberSettings" :key="setting.id">
              <tbody :name="setting.region">
                <tr>
                  <td rowspan="17" class="title">
                    {{ region_text[setting.region] }}
                  </td>
                </tr>
                <template v-for="numbertypedetail in numbertypedetails" :key="numbertypedetail.title">
                  <tr v-if="!(setting.region == 'mb' && numbertypedetail.field == 'dc')">
                    <td>{{ numbertypedetail.title }}</td>
                    <td>
                      <b-form-input v-model="setting[numbertypedetail.field]" type="number"></b-form-input>
                      <p
                        class="danger"
                        v-if="_.get(store.$state.errors, `customersNumberSettings.${index}.${numbertypedetail.field}`)"
                      >
                        {{ _.get(store.$state.errors, `customersNumberSettings.${index}.${numbertypedetail.field}`) }}
                      </p>
                    </td>
                    <td>
                      <b-form-input v-model="setting[numbertypedetail.winningField]" type="number"></b-form-input>
                      <p
                        class="danger"
                        v-if="
                          _.get(
                            store.$state.errors,
                            `customersNumberSettings.${index}.${numbertypedetail.winningField}`
                          )
                        "
                      >
                        {{
                          _.get(
                            store.$state.errors,
                            `customersNumberSettings.${index}.${numbertypedetail.winningField}`
                          )
                        }}
                      </p>
                    </td>
                  </tr>
                </template>

                <template v-if="store.$state.customer.commission_calculation_type != 'none'">
                  <template v-if="store.$state.customer.profit_type == 0">
                    <tr v-for="profit_type in profit_type_field_0" :key="profit_type.title">
                      <td>{{ profit_type.title }}</td>
                      <td colspan="2">
                        <b-form-input v-model="setting[profit_type.field]" type="number"></b-form-input>
                        <p
                          class="danger"
                          v-if="_.get(store.$state.errors, `customersNumberSettings.${index}.${profit_type.field}`)"
                        >
                          {{ _.get(store.$state.errors, `customersNumberSettings.${index}.${profit_type.field}`) }}
                        </p>
                      </td>
                    </tr>
                  </template>
                  <template v-if="store.$state.customer.profit_type == 1">
                    <tr>
                      <td>Lai tổng phơi</td>
                      <td colspan="2">
                        <b-form-input v-model="setting.lai_all" type="number"></b-form-input>
                        <p class="danger" v-if="_.get(store.$state.errors, `customersNumberSettings.${index}.lai_all`)">
                          {{ _.get(store.$state.errors, `customersNumberSettings.${index}.lai_all`) }}
                        </p>
                      </td>
                    </tr>
                  </template>

                  <template v-if="store.$state.customer.profit_type == 2">
                    <tr>
                      <td>Lai tổng 2 số</td>
                      <td colspan="2">
                        <b-form-input v-model="setting.lai_2digits" type="number"></b-form-input>
                        <p
                          class="danger"
                          v-if="_.get(store.$state.errors, `customersNumberSettings.${index}.lai_2digits`)"
                        >
                          {{ _.get(store.$state.errors, `customersNumberSettings.${index}.lai_2digits`) }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>Lai tổng 3 số</td>
                      <td colspan="2">
                        <b-form-input v-model="setting.lai_3digits" type="number"></b-form-input>
                        <p
                          class="danger"
                          v-if="_.get(store.$state.errors, `customersNumberSettings.${index}.lai_3digits`)"
                        >
                          {{ _.get(store.$state.errors, `customersNumberSettings.${index}.lai_3digits`) }}
                        </p>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </template>
            <tr>
              <td colspan="4">
                <b-button
                  variant="primary"
                  v-b-toggle="`collapse-${customer.id}`"
                  type="submit"
                  block
                  @click.prevent="createOrUpdate(customer)"
                  >{{ store.$state.btnText }}</b-button
                >
              </td>
            </tr>
          </table>
        </b-col>
      </b-form>
    </b-row>
  </b-container>
</template>

<style scoped>
.title {
  vertical-align: middle !important;
  text-align: center;
}

.b-table > tbody > tr:last-child {
  font-weight: normal !important; /* Override the bold style */
}

.tooltip-btn {
  background-color: transparent;
  border: none;
}

.danger {
  color: red;
}
</style>
