<script>
export default {
  name: "LotteryComponent",
};
</script>

<script setup>
import _ from "lodash";
import { useStore } from "vuex";
import { useLotteryStore } from "@/stores/lottery.module";
import { useCommonStore } from "@/stores/common.module";
import MessageSummary from "@/components/MessageSummary.vue";
import MessageDetail from "@/components/MessageDetail.vue";
import { listNumbersTableFields, SYNTAX_TITLE, PROVINCE_TITLE } from "@/utils/constants";

const store = useStore();

const calc_type = store.state.auth.user.calculation_type;

const lotteryStore = useLotteryStore();
const commonStore = useCommonStore();

lotteryStore.$state.loading = true;
lotteryStore
  .init()
  .catch((err) => {
    console.log(err);
    commonStore.err("Có lỗi trong quá trình xử lý");
  })
  .finally(() => {
    lotteryStore.$state.loading = false;
  });

function load(action) {
  if (
    action == 2 &&
    lotteryStore.$state.date != lotteryStore.$state.lotteryDate &&
    !confirm("Ngày tính tiền và ngày dò không giống nhau, bạn có chắc muốn dò không?")
  ) {
    return;
  }

  lotteryStore.$state.action = action;
  if (action == 3) {
    lotteryStore.getListNumbers();
  } else {
    lotteryStore.getList(action);
  }
}

function copy() {
  const text = lotteryStore.totalTextToCopy;
  navigator.clipboard.writeText(text).then(() => {
    lotteryStore.$state.showCopied = true;

    setTimeout(() => (lotteryStore.$state.showCopied = false), 3000);
  });
}
</script>

<template>
  <b-container>
    <b-row class="justify-content-center justify-content-md-center">
      <b-cols><h1>Dò số</h1></b-cols>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Tên khách hàng">
          <b-form-select
            v-model="lotteryStore.$state.selectedCustomer"
            :options="lotteryStore.$state.customers"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Ngày tính tiền">
          <b-form-datepicker v-model="lotteryStore.$state.date"></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Ngày dò số">
          <b-form-datepicker v-model="lotteryStore.$state.lotteryDate"></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Miền">
          <b-form-radio-group
            v-model="lotteryStore.$state.selectedRegion"
            :options="lotteryStore.$state.region"
            class="mb-3"
            value-field="value"
            text-field="text"
            disabled-field="notEnabled"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-center justify-content-md-center">
      <b-col cols="4" md="3">
        <b-button block variant="light" @click.prevent="load(1)" :disabled="lotteryStore.$state.loading">
          <span v-show="lotteryStore.$state.loading" class="spinner-border spinner-border-sm" />
          <span>Tải tin nhắn</span>
        </b-button>
      </b-col>
      <b-col cols="4" md="3">
        <b-button block variant="light" @click.prevent="load(2)" :disabled="lotteryStore.$state.loading">
          <span v-show="lotteryStore.$state.loading" class="spinner-border spinner-border-sm" />
          <span>Dò số</span>
        </b-button>
      </b-col>
      <b-col cols="4" md="3">
        <b-button block variant="light" @click.prevent="load(3)" :disabled="lotteryStore.$state.loading">
          <span v-show="lotteryStore.$state.loading" class="spinner-border spinner-border-sm" />
          <span>Thống kê số</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="lotteryStore.$state.error != ''">
      <b-col cols="12" md="4" class="mx-auto" style="color: red">
        <h1 style="text-align: center">{{ lotteryStore.$state.error }}</h1>
      </b-col>
    </b-row>
    <b-row v-if="lotteryStore.$state.error == '' && !_.isEmpty(lotteryStore.$state.messages)" class="pt-3">
      <b-col cols="12">
        <message-summary :messageSummary="lotteryStore.$state.total" :calc_type="calc_type" />
      </b-col>
      <b-col cols="12">
        <b-button @click.prevent="copy">Sao chép kết quả</b-button>
        <span v-show="lotteryStore.$state.showCopied" class="pl-3">Đã sao chép vào bộ nhớ đệm</span>
      </b-col>
    </b-row>

    <template v-if="lotteryStore.$state.action == 3">
      <b-row style="margin-top: 30px" v-if="lotteryStore.$state.selectedRegion != 'mb'">
        <b-col>
          <b-col>
            <b-form-group label="Đài">
              <b-form-checkbox-group
                v-model="lotteryStore.$state.reportNumbers.selectedProvinces"
                :options="lotteryStore.provinceFilterChoices"
                class="mb-3"
                value-field="item"
                text-field="name"
              ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-col>
      </b-row>
      <b-row style="margin-top: 30px">
        <b-col>
          <b-col>
            <b-form-group label="Kiểu đánh">
              <b-form-checkbox-group
                v-model="lotteryStore.$state.reportNumbers.selectedNumbertype"
                :options="lotteryStore.numbertypeFilterChoices"
                class="mb-3"
                value-field="item"
                text-field="name"
              ></b-form-checkbox-group>
            </b-form-group>
          </b-col>
        </b-col>
      </b-row>
      <b-row style="margin-top: 30px">
        <b-col>
          <b-table striped hover :items="lotteryStore.filteredNumbers" :fields="listNumbersTableFields">
            <template v-slot:cell(numbertypeid)="data">
              {{ SYNTAX_TITLE[data.item.numbertypeid] }}
            </template>
            <template v-slot:cell(province_index)="data">
              <span v-if="lotteryStore.$state.selectedRegion != 'mb'">
                {{
                  PROVINCE_TITLE[lotteryStore.$state.selectedRegion][lotteryStore.dayOfWeek][data.item.province_index]
                }}
              </span>
              <span v-else> Mb </span>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </template>
    <template v-if="lotteryStore.$state.action != 3">
      <b-row v-for="(message, index) in lotteryStore.$state.messages" :key="index" class="pt-4">
        <b-col cols="12">
          <message-detail :message="message" :calc_type="calc_type" :index="index" />
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<style>
.my-item {
  word-wrap: break-word;
  border: 1px solid #ddd;
  padding: 20px;
}
@media only screen and (max-width: 600px) {
  table {
    font-size: 14px;
  }
}
</style>
