<script setup>
import { defineProps } from "vue";
import { useSyntaxStore } from "@/stores/syntax.module";
import _ from "lodash";

const syntaxStore = useSyntaxStore();
const props = defineProps({
  region: String,
  weekday: String,
  province_index: String,
});
</script>

<template>
  <b-input
    v-model="syntaxStore.$state.displayProvinces[props.region][props.weekday][props.province_index]"
    @change="syntaxStore.onChangeProvince(props.region, props.weekday, props.province_index)"
    :state="_.isEmpty(_.get(syntaxStore.$state.errors, [region, weekday, province_index])) ? null : false"
    :disabled="syntaxStore.$state.posting"
  ></b-input>
</template>
