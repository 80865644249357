import client from "./http-common";

class MessageService {
  calculate(data) {
    return client.post("/message/create-or-update", data);
  }
  get(id) {
    return client.get(`/messages/${id}`);
  }
  getList(data) {
    return client.post("/message/list", data);
  }
  getListNumbers(data) {
    return client.post("/message/list-numbers", data);
  }
  del(id) {
    return client.delete(`/message/delete?id=${id}`);
  }
  report(from, to, ids, findWinningNumber) {
    const data = {
      from: from,
      to: to,
      ids: ids,
      findWinningNumber: findWinningNumber,
    };
    return client.post(`/message/report`, data);
  }
}

export default new MessageService();
