import { defineStore } from "pinia";
import { toast } from "vue3-toastify";

export const useCommonStore = defineStore("common", {
  state: () => {
    return {
      snackbar: {
        visible: false,
        text: "",
        color: "",
      },
      loading: false,
    };
  },
  actions: {
    success(message) {
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    err(message) {
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    warning(message) {
      toast.warning(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
});
