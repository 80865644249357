import messageErrorService from "@/services/message.error.service";
import { defineStore } from "pinia";
import Delta from "quill-delta";
import _ from "lodash";

export const useMessageErrorStore = defineStore("messageError", {
  state: () => {
    return {
      data: [],
      page: 1,
      pageSize: 5,
    };
  },

  actions: {
    fetch() {
      return messageErrorService
        .get(this.page, this.pageSize)
        .then((res) => {
          let messages = res.data;
          for (let i = 0; i < messages.length; i++) {
            const reg = new RegExp(`${messages[i].error_pattern}`, "gi");
            const splitted = _.compact(messages[i].message.split(reg));

            const delta = new Delta(
              _.map(splitted, function (x) {
                return {
                  insert: x,
                  attributes: {
                    background: x.match(reg) ? "#ffff00" : "",
                  },
                };
              })
            );

            messages[i].quillContent = delta;
          }

          this.data = [...this.data, ...messages];
          this.page++;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
