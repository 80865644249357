import moment from "moment";
import UserService from "../services/user.service";
import router from "@/router"; // Import your Vue Router instance

// const user = JSON.parse(localStorage.getItem("user"));
// const initialState = user
//   ? { status: { loggedIn: true }, user }
//   : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: {
    form: {
      username: "",
      password: "",
    },
    error: "",
    user: JSON.parse(localStorage.getItem("user")),
    loading: false,
  },
  getters: {
    isLoggedIn: (state) => !!state.user,
    isAdmin: (state) => state.user && state.user.username == "bi",
    user: (state) => state.user,
    isShowExpiredMsg: (state) => {
      if (!state.user) return false;
      if (!state.user.expired_date) return false;

      const expiredDate = moment(state.user.expired_date, "YYYYMMDD");
      const currentDate = moment(new Date());

      return expiredDate.diff(currentDate, "days") <= 5;
    },
  },
  actions: {
    login({ commit, state, dispatch }) {
      commit("setLoading", true);
      commit("clearError");

      return UserService.login(state.form)
        .then((res) => {
          const data = res.data;

          if (data.success) {
            commit("loginSuccess", data.data);
            return Promise.resolve();
          } else {
            commit("loginFailure", data.errors[0]);
            return Promise.resolve();
          }
        })
        .catch(() => {
          dispatch("error", "Có lỗi trong quá trình xử lý", { root: true });
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    refreshToken({ commit }) {
      UserService.refreshToken()
        .then((res) => {
          const data = res.data;
          if (data.success) {
            commit("refreshToken", data.data);
            return Promise.resolve();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    logout({ commit }) {
      commit("logout");
    },
  },
  mutations: {
    clearError(state) {
      state.error = "";
    },
    refreshToken(state, user) {
      localStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },
    loginSuccess(state, user) {
      localStorage.setItem("user", JSON.stringify(user));
      state.user = user;
      router.push("/notification");
    },
    loginFailure(state, error) {
      state.errorState = false;
      state.error = error;
      state.user = null;
    },
    logout(state) {
      state.user = null;
      localStorage.removeItem("user");
      router.push("/login");
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
};
