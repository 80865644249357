<script>
export default {
  name: "SyntaxComponent",
};
</script>

<script setup>
import { useSyntaxStore, WEEKDAY_TITLE, REGION_TITLE } from "@/stores/syntax.module";
import { useCommonStore } from "@/stores/common.module";
import SyntaxInput from "@/components/SyntaxInput.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { PROVINCE_TITLE } from "@/utils/constants";
import _ from "lodash";

const commonStore = useCommonStore();
const syntaxStore = useSyntaxStore();

syntaxStore.$state.loading = true;
syntaxStore
  .fetch()
  .catch((err) => {
    console.log(err);
    commonStore.err("Có lỗi trong quá trình xử lý");
  })
  .finally(() => {
    syntaxStore.$state.loading = false;
  });
</script>

<template>
  <b-container>
    <b-row class="justify-content-center justify-content-md-center">
      <h1>Cài đặt cú pháp đài</h1>
    </b-row>

    <b-row class="justify-content-md-center">
      <b-col cols="12">
        <loading-spinner v-if="syntaxStore.$state.loading" />
        <template v-else>
          <b-card class="none-border" v-for="(key, region) in syntaxStore.$state.displayProvinces" :key="region">
            <b-card-title style="text-align: center">{{ REGION_TITLE[region] }}</b-card-title>
            <!-- {{ REGION_TITLE[region] }} -->
            <template v-for="(key2, weekday) in key" :key="weekday">
              <b-card :header="WEEKDAY_TITLE[weekday]" class="none-border">
                <template v-for="(key3, province_index) in key2" :key="province_index">
                  <b-row>
                    <b-col cols="4" cols-md="2">{{ PROVINCE_TITLE[region][weekday][province_index] }}</b-col>
                    <b-col cols="8" cols-md="10">
                      <syntax-input :region="region" :weekday="weekday" :province_index="province_index" />
                    </b-col>
                    <b-col
                      cols="12"
                      v-if="_.get(syntaxStore.$state.errors, [region, weekday, province_index])"
                      class="mb-3"
                    >
                      <span
                        style="color: red"
                        v-html="_.get(syntaxStore.$state.errors, [region, weekday, province_index])"
                      ></span>
                    </b-col>
                  </b-row>
                </template>
              </b-card>
            </template>
          </b-card>
          <b-button block variant="primary" class="mb-5" @click="syntaxStore.saveProvince">
            <b-spinner label="Spinning" v-if="syntaxStore.$state.posting"></b-spinner>
            <span v-else>Lưu</span>
          </b-button>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
.none-border {
  border: none;
}
</style>
