<script>
export default {
  name: "LoginComponent",
};
</script>
<script setup>
import router from "@/router";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();

let state = store.state.auth;
const errorState = computed(() => {
  return !state.error;
});

if (state.user) {
  router.push("notification");
}

const login = () => {
  store.dispatch("auth/login");
};
</script>

<template>
  <div class="col-md-12">
    <div class="card card-container">
      <b-form @submit.prevent="login">
        <b-form-group id="username-group" label="Tài khoản:" label-for="password">
          <b-form-input id="username" type="text" v-model="state.form.username" placeholder="Nhập tài khoản" required></b-form-input>
        </b-form-group>
        <b-form-group id="password-group" label="Mật khẩu:" label-for="password">
          <b-form-input id="password" type="password" v-model="state.form.password" placeholder="Nhập mật khẩu" required></b-form-input>
        </b-form-group>
        <b-form-invalid-feedback :state="errorState">{{ state.error }}</b-form-invalid-feedback>
        <b-form-group>
          <b-button type="submit" variant="primary" block :disabled="state.loading">
            <span v-show="state.loading" class="spinner-border spinner-border-sm" />
            <span>Login</span>
          </b-button>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.error-feedback {
  color: red;
}
</style>
