import { createStore } from "vuex";
import { auth } from "./auth.module";
import { notification } from "./notification.module";
import { settings } from "./settings.module";
// import { customer } from "./customer.module";
// import { report } from "./report.module";
import { password } from "./password.module";
import { feedback } from "./feedback.module";
// import { calculate } from "./calculate.module";
// import { lottery } from "./lottery.module";
import { manager } from "./manager.module";
import { manager2 } from "./manager2.module";
import { manager3 } from "./manager3.module";
import { toast } from "vue3-toastify";
const store = createStore({
  state: {
    loading: false,
  },
  actions: {
    success(_, message) {
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
    error(_, message) {
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
      });
    },
  },
  mutations: {
    loading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  modules: {
    auth,
    notification,
    settings,
    // customer,
    // report,
    password,
    feedback,
    // calculate,
    // lottery,
    manager,
    manager2,
    manager3,
  },
});

export default store;
