import client from "./http-common";

class NumbertypeService {
  fetch() {
    return client.get("numbertypedetail");
  }
  create(data) {
    return client.post("numbertypedetail/create", data);
  }
}

export default new NumbertypeService();
