<script setup>
import { defineProps } from "vue";
import router from "@/router";
import { useLotteryStore } from "@/stores/lottery.module";
import { ref, getCurrentInstance } from "vue";
import _ from "lodash";

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  index: Number,
  message: Object,
  calc_type: String,
});
const lotteryStore = useLotteryStore();
const internalInstance = getCurrentInstance();
const confirmMessage = ref("");

function update(id) {
  router.push(`/calculate/${id}`);
}
function del(id, index) {
  const bvModal = internalInstance.proxy._bv__modal;

  confirmMessage.value = `Bạn có chắc muốn xoá tin nhắn số ${index + 1} ?`;
  bvModal
    .msgBoxConfirm(confirmMessage.value, {
      size: "md",
      buttonSize: "sm",
      okVariant: "danger",
      cancelVariant: "secondary",
      okTitle: "Xoá",
      cancelTitle: "Huỷ",
    })
    .then((value) => {
      if (value) {
        lotteryStore.del(id);
      }
    });
}

function feedback(id) {
  router.push(`/feedback/${id}`);
}
</script>

<template>
  <b-card :title="index + 1">
    <b-row>
      <!--message-->
      <b-col cols="12" md="5">
        <b-card-text style="white-space: break-spaces">{{ message.message }}</b-card-text>
      </b-col>
      <!--total-->
      <b-col cols="12" md="5" class="pt-4 pt-md-0">
        <b-card-text style="font-weight: bold">Tổng:</b-card-text>
        <b-card-text class="pl-2">
          <dl v-if="message.sum_2digits > 0">
            <dt class="two-digits-bold">2 số :{{ message.sum_2digits }}</dt>
            <dd v-if="message.haiso > 0">- Hai số: {{ message.haiso }}</dd>
            <dd v-if="message.dd > 0">- Đầu đuôi: {{ message.dd }}</dd>
            <dd v-if="message.dt > 0">- Đá thẳng: {{ message.dt }}</dd>
            <dd v-if="message.dc > 0">- Đá chéo: {{ message.dc }}</dd>
          </dl>
          <dl v-if="message.sum_3digits > 0">
            <dt class="three-digits-bold">3 số :{{ message.sum_3digits }}</dt>
            <dd v-if="message.baso > 0">- Ba số: {{ message.baso }}</dd>
            <dd v-if="message.bonso > 0">- Bốn số: {{ message.bonso }}</dd>
            <dd v-if="message.xc > 0">- Xỉu chủ: {{ message.xc }}</dd>
          </dl>
          <dl v-if="message.sum_win_2digits > 0">
            <dt class="two-digits-bold">Trúng 2 số :{{ message.sum_win_2digits }}</dt>
            <dd v-if="message.trung2so > 0">- Hai số: {{ message.trung2so }}</dd>
            <dd v-if="message.trungdd > 0">- Đầu đuôi: {{ message.trungdd }}</dd>
            <dd v-if="message.trungdt > 0">- Đá thẳng: {{ message.trungdt }}</dd>
            <dd v-if="message.trungdc > 0">- Đá chéo: {{ message.trungdc }}</dd>
          </dl>
          <dl v-if="message.sum_win_3digits > 0">
            <dt class="three-digits-bold">Trúng 3 số :{{ message.sum_win_3digits }}</dt>
            <dd v-if="message.trungbaso > 0">- Ba số: {{ message.trungbaso }}</dd>
            <dd v-if="message.trungbonso > 0">- Bốn số: {{ message.trungbonso }}</dd>
            <dd v-if="message.trungxc > 0">- Xỉu chủ: {{ message.trungxc }}</dd>
          </dl>
        </b-card-text>

        <!--Số trúng-->
        <b-card-text style="font-weight: bold" class="pt-4" v-if="!_.isEmpty(message.messagedetails)"
          >Số trúng:</b-card-text
        >
        <b-card-text class="pl-2" style="color: red" v-for="(detail, index) in message.messagedetails" :key="index">
          <span v-if="detail.winningNumber.length > 0">
            <span v-if="detail.numbertypeId == 2 || detail.numbertypeId == 4 || detail.numbertypeId == 13"></span>
            {{ detail.winningNumber }}
          </span>
          <span v-else>{{ detail.number }} ({{ detail.matches }}) </span>
          <span> - {{ detail.originalType }} </span>
          <span> - Tiền trúng : {{ detail.winningMoney }}</span>
        </b-card-text>
      </b-col>
      <b-col cols="12" md="2">
        <b-row>
          <b-col cols="4" md="12"
            ><b-button block variant="primary" @click.prevent="update(message.id)">Sửa</b-button></b-col
          >
          <b-col cols="4" md="12" class="pt-md-1">
            <b-button block variant="danger" @click.prevent="del(message.id, index)">Xoá</b-button>
          </b-col>
          <b-col cols="4" md="12" class="pt-md-1">
            <b-button block variant="warning" @click.prevent="feedback(message.id)">Báo lỗi</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>
