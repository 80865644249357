<script>
export default {
  name: "Manager2Component",
};
</script>

<script setup>
// import { computed } from "vue";
import { useStore } from "vuex";
// import { useRoute } from "vue-router";
// import _ from "lodash";

const store = useStore();
const state = store.state.manager2;

function create() {
  store.dispatch("manager2/create");
}
</script>

<template>
  <b-container class="pt-3">
    <b-row class="justify-content-center justify-content-md-center">
      <b-cols><h1>Manager2</h1></b-cols>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label-cols="4" label-cols-lg="2" label="Username" label-for="username">
          <b-form-input id="username" v-model="state.user.username"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-cols="4" label-cols-lg="2" label="Password" label-for="password">
          <b-form-input id="password" v-model="state.user.password"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-cols="4" label-cols-lg="2" label="Calculation Type" label-for="calculation_type">
          <b-form-input id="calculation_type" v-model="state.user.calculation_type"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-cols="4" label-cols-lg="2" label="Expiration" label-for="expired_date">
          <b-form-input id="expired_date" v-model="state.user.expired_date"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-cols="4" label-cols-lg="2" label="Group" label-for="groupname">
          <b-form-input id="groupname" v-model="state.user.groupname"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button variant="primary" @click.prevent="create" block>Create</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<style></style>
