<script>
export default {
  name: "SettingsComponent",
};
</script>

<script setup>
import { useStore } from "vuex";

const store = useStore();
const state = store.state.settings;

store.dispatch("settings/fetchSettings");

function update() {
  store.commit("settings/setIsLoading", true);

  store
    .dispatch("settings/update")
    .then(() => store.dispatch("success", "Cập nhật thành công"))
    .catch(() => store.dispatch("error", "Có lỗi trong quá trình xử lý"))
    .finally(() => store.commit("settings/setIsLoading", false));
}
</script>

<template>
  <b-container>
    <b-row class="justify-content-center justify-content-md-center">
      <b-cols><h1>Cài đặt</h1></b-cols>
    </b-row>
    <b-row v-for="(setting, index) in state.settings" :key="index" class="pt-4">
      <b-col cols="3">
        {{ setting.title }}
      </b-col>
      <b-col cols="9">
        <b-form-checkbox v-model="setting.userSettings.setting_value" :checked="store.getters['settings/isSelected'](setting.id)">
          <p v-html="setting.description"></p>
        </b-form-checkbox>
      </b-col>
      <b-col offset="3" cols="9" md="6" v-if="setting.detail_display == 1">
        <p v-html="setting.detail_label"></p>
        <b-form-textarea v-model="setting.userSettings.detail"></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-center">
          <b-button type="submit" variant="primary" :disabled="state.isLoading" @click.prevent="update">
            <span v-show="state.isLoading" class="spinner-border spinner-border-sm" />
            <span>Cập nhật</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
