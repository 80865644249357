import client from "./http-common";

class ProvinceService {
  fetch() {
    return client.get("province");
  }
  create(data) {
    return client.post("province/create", data);
  }
}

export default new ProvinceService();
