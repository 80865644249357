import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import HoneybadgerVue from "@honeybadger-io/vue";
import Honeybadger from "@honeybadger-io/js";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueMobileDetection from "vue-mobile-detection";
require("./assets/main.css");

import "vue3-toastify/dist/index.css";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createPinia } from "pinia";
// import VueAnalytics from "vue-analytics";
import VueGtag from "vue-gtag";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(BootstrapVue);
app.use(BootstrapVueIcons);
app.use(VueMobileDetection);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(createPinia());

// app.use(VueAnalytics, {
//   id: "G-R32D6PGNJQ",
//   autoTracking: {
//     exception: true,
//     screenview: true,
//     pageviewOnLoad: true,
//   },
// });

const options = {
  config: {
    id: "G-R32D6PGNJQ", // replace GA_MEASUREMENT_ID with your ID
    // params: {
    //   user_id: "foo",
    // },
  },
};

app.config.errorHandler = (err, vm, info) => {
  console.error("Global error handler:", err, vm, info);
  if (vm) {
    // Handle the error with Honeybadger
    Honeybadger.notify(err, {
      context: {
        component: vm.$options.name,
        propsData: vm.$props,
        info,
      },
    });
  } else {
    // Handle the error without vm
    Honeybadger.notify(err, {
      context: {
        info,
      },
    });
  }
};
app.use(VueGtag, options, router);

const honeyBadgerConfig = {
  apiKey: "hbp_STt9OKydfBPFKOdmY7VvEvHvWK8x9M2s2ZVG",
  environment: process.env.NODE_ENV,
};

app.use(HoneybadgerVue, honeyBadgerConfig);

app.mount("#app");
