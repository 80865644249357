import userService from "@/services/user.service";
// import { error } from "jquery";

export const password = {
  namespaced: true,
  state: {
    password: "",
    confirmPassword: "",
    loading: false,
  },

  getters: {},

  actions: {
    update({ commit, state }) {
      if (state.password !== state.confirmPassword) {
        return Promise.reject("Mật khẩu không khớp");
      }
      if (state.password === "" || state.confirmPassword === "") {
        return Promise.reject("Mật khẩu không được để trống");
      }

      commit("setLoading", false);
      return userService.changePassword(state.password).finally(() => {
        commit("setLoading", false);
      });
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
};
