import client from "./http-common";

class UserService {
  login(user) {
    return client.post("user/login2", {
      username: user.username,
      password: user.password,
    });
  }

  changePassword(password) {
    return client.post("user/change-password", {
      password,
    });
  }
  feedback(data) {
    return client.post("user/feedback", data);
  }
  refreshToken() {
    return client.put("user/refresh-token");
  }
  list() {
    return client.get("user/list");
  }
  extend(data) {
    return client.post("user/extend-users", data);
  }
  create(data) {
    return client.post("user/create", data);
  }
  update(data) {
    return client.put(`user/update/${data.username}`, data);
  }
  getSyntax() {
    return client.get(`user/syntax/`);
  }
}

export default new UserService();
