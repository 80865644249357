import axios from "axios";
import store from "@/store";

const client = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

client.interceptors.request.use(
  (config) => {
    if (config.url != "user/refresh-token" && config.url != "user/login2") {
      store.dispatch("auth/refreshToken");
    }

    let user = JSON.parse(localStorage.getItem("user"));
    if (user && user.access_token) {
      config.headers.Authorization = `Bearer ${user.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const url = error.config.url;
    if (error.response && error.response.status === 401 && url != "user/refresh-token") {
      store.dispatch("auth/logout");
    }
    return Promise.reject(error);
  }
);

export default client;
