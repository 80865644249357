<script setup>
// import { ref } from "vue";
import { useStore } from "vuex";
import { useCommonStore } from "./stores/common.module";
// import { storeToRefs } from "pinia";

const store = useStore();

const state = store.state.auth;
// // const rootState = store.state;
const commonStore = useCommonStore();

// const isLoading = storeToRefs(commonStore.isLoading);

const logout = () => {
  store.dispatch("auth/logout");
};
// const show = ref(true);
</script>

<template>
  <div class="vh-100 d-flex flex-column">
    <header>
      <b-navbar toggleable="lg" type="dark" variant="dark" v-if="state.user" sticky>
        <b-navbar-brand href="#">Tính tiền</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item to="/calculate" exact exact-active-class="active"> Tính tiền </b-nav-item>
            <b-nav-item to="/lottery" exact exact-active-class="active"> Dò số </b-nav-item>
            <b-nav-item to="/report" exact exact-active-class="active"> Thống kê </b-nav-item>
            <b-nav-item to="/customers" exact exact-active-class="active"> Quản lý khách hàng </b-nav-item>
            <b-nav-item to="/settings" exact exact-active-class="active"> Cài đặt </b-nav-item>
            <b-nav-item to="/syntax" exact exact-active-class="active">Cài đặt đài</b-nav-item>
            <b-nav-item to="/syntax2" exact exact-active-class="active">Cài đặt cú pháp</b-nav-item>
            <b-nav-item to="/feedback" exact exact-active-class="active"> Hỗ trợ kỹ thuật </b-nav-item>

            <b-nav-item v-if="store.getters['auth/isAdmin']" to="/manager" exact exact-active-class="active">
              Manager
            </b-nav-item>
            <b-nav-item v-if="store.getters['auth/isAdmin']" to="/manager2" exact exact-active-class="active">
              Manager2
            </b-nav-item>
            <b-nav-item v-if="store.getters['auth/isAdmin']" to="/manager3" exact exact-active-class="active">
              Manager3
            </b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right>
              <template #button-content>
                <em>Cá nhân({{ state.user.username }})</em>
              </template>
              <b-dropdown-item to="/notification">Thông báo</b-dropdown-item>
              <b-dropdown-item to="/password">Đổi mật khẩu</b-dropdown-item>
              <b-dropdown-item to="#" @click.prevent="logout"> Đăng xuất </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </header>
    <main class="flex-grow-1 overflow-auto">
      <h5 v-if="store.getters['auth/isShowExpiredMsg']" style="text-align: center; color: red" class="pt-2">
        Tài khoản của bạn sắp hết hạn, xin vui lòng liên hệ với người quản trị để quá trình sử dụng không bị gián đoạn
      </h5>

      <b-overlay :show="commonStore.$state.loading" rounded="sm">
        <router-view />
        <div style="height: 85vh" v-if="commonStore.$state.loading"></div>
      </b-overlay>
    </main>
  </div>
</template>

<style>
.justify-content-center {
  justify-content: center;
}
</style>
