import { defineStore } from "pinia";
import customerService from "@/services/customer.service";
import { useCommonStore } from "./common.module";
import _ from "lodash";

export const customer_fields = [
  { key: "name", label: "Tên khách hàng" },
  { key: "profit_type", label: "Kiểu lai" },
  { key: "sign", label: "Khách/thầu" },
  { key: "bonus_dt_type", label: "Thưởng đá" },
  { key: "bonus_dt_type_mb", label: "Thưởng đá mb" },
  { key: "action", label: "Xoá" },
];

export const useCustomerStore = defineStore("customer", {
  state: () => {
    return {
      customers: [],
      customer: {},
      loading: false,
    };
  },

  getters: {
    regionIndices() {
      console.log("regionIndices");
      let regionIndices = {};
      if (_.isEmpty(this.customer?.customersNumberSettings)) {
        return regionIndices;
      }
      this.customer.customersNumberSettings.forEach((item, index) => {
        regionIndices[item.region] = index;
      });
      return regionIndices;
    },
  },

  actions: {
    async fetch() {
      const commonStore = useCommonStore();
      this.loading = true;
      console.log(commonStore.$state.loading);
      return customerService
        .fetch()
        .then((res) => {
          if (_.isEmpty(res.data)) {
            commonStore.err("Chưa có thông tin khách hàng, xin hãy thêm thông tin khách hàng");
          }
          let customers = res.data;
          this.customers = customers;
        })
        .catch(() => commonStore.err("Có lỗi xảy ra, xin hãy thử lại sau!"))
        .finally(() => (this.loading = false));
    },
    delete(id) {
      const commonStore = useCommonStore();
      return customerService
        .delete(id)
        .then(() => {
          commonStore.success("Xóa thông tin khách hàng thành công!");
          this.fetch();
        })
        .catch(() => {
          commonStore.err("Có lỗi xảy ra, xin hãy thử lại sau!");
        });
    },
  },
});
