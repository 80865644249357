<script>
export default {
  name: "Manager3Component",
};
</script>

<script setup>
// import { computed } from "vue";
import { useStore } from "vuex";
// import { useRoute } from "vue-router";
// import _ from "lodash";

const store = useStore();
const state = store.state.manager3;
store.dispatch("manager3/fetchUsers");

function update(user) {
  store.dispatch("manager3/update", user);
}
</script>

<template>
  <b-container class="pt-3">
    <b-row class="justify-content-center justify-content-md-center">
      <b-cols><h1>Manager3</h1></b-cols>
    </b-row>

    <b-row class="pt-5">
      <b-col>
        <table class="table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Password</th>
              <th>Calculation</th>
              <th>Expiration</th>
              <th>Group</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in state.users" :key="index">
              <td>
                {{ state.users[index].username }}
              </td>
              <td>
                <b-form-input type="text" v-model="state.users[index].password"></b-form-input>
              </td>
              <td>
                <b-form-input type="text" v-model="state.users[index].calculation_type"></b-form-input>
              </td>
              <td>
                <b-form-input type="text" v-model="state.users[index].expired_date"></b-form-input>
              </td>
              <td>
                <b-form-input type="text" v-model="state.users[index].groupname"></b-form-input>
              </td>
              <td>
                <b-button variant="primary" @click.prevent="update(user)">Update</b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-container>
</template>

<style></style>
