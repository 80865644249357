import customerService from "@/services/customer.service";
import messageService from "@/services/message.service";
import moment from "moment";
import { defineStore } from "pinia";
import { useCommonStore } from "./common.module";
import { PROVINCE_TITLE, SYNTAX_TITLE } from "@/utils/constants";
import _ from "lodash";

export const useLotteryStore = defineStore("lottery", {
  state: () => {
    return {
      selectedCustomer: "",
      customers: [],
      date: moment(new Date()).format("YYYY-MM-DD"),
      lotteryDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedRegion: "mn",
      region: [
        { text: "Miền Nam", value: "mn" },
        { text: "Miền Trung", value: "mt" },
        { text: "Miền Bắc", value: "mb" },
      ],
      error: "",
      total: {},
      messages: [],
      showCopied: false,
      loading: true,
      action: 0,
      reportNumbers: {
        numbers: [],
        filter: {},
        dayofweek: 0,
        selectedProvinces: [],
        selectedNumbertype: [],
      },
    };
  },
  getters: {
    showTotal2Digits: (state) => state.total.money_2s != 0 || state.total.win_2s != 0 || state.total.profit_2s != 0,
    showTotal3Digits: (state) => state.total.money_3s != 0 || state.total.win_3s != 0 || state.total.profit_3s != 0,
    showTotal4Digits: (state) => state.total.money_4s != 0 || state.total.win_4s != 0 || state.total.profit_4s != 0,
    showTotalDd: (state) => state.total.money_dd != 0 || state.total.win_dd != 0 || state.total.profit_dd != 0,
    showTotalXc: (state) => state.total.money_xc != 0 || state.total.win_xc != 0 || state.total.profit_xc != 0,
    showTotalDt: (state) => state.total.money_dt != 0 || state.total.win_dt != 0 || state.total.profit_dt != 0,
    showTotalDc: (state) => state.total.money_dc != 0 || state.total.win_dc != 0 || state.total.profit_dc != 0,
    showGrandTotal2Digits: (state) =>
      state.total.sum_money_2digits + state.total.win_2digits + state.total.profit_2digits != 0,
    showGrandTotal3Digits: (state) =>
      state.total.sum_money_3digits + state.total.win_3digits + state.total.profit_3digits != 0,
    winningNumber: () => (messageId, detailId) => `${messageId}_${detailId}`,
    selectedCustomerObj: (state) => _.find(state.customers, (x) => x.id == state.selectedCustomer),
    thuchiText(state) {
      const sign = this.selectedCustomerObj.sign;
      const total = state.total.commission || state.total.profit;

      console.log(this.selectedCustomerObj);

      return `${sign * total > 0 ? "Thu" : "Chi"}`;
    },
    totalTextToCopy(state) {
      const mien = _.find(state.region, (x) => x.value == state.selectedRegion).text;
      let text = "";
      text += `${mien} | Khách: ${this.selectedCustomerObj.name}\n`;
      if (this.showTotal2Digits) {
        text += `Hai số: ${state.total.money_2s} | Trúng: ${state.total.win_2s}\n`;
      }
      if (this.showTotal3Digits) {
        text += `Ba số: ${state.total.money_3s} | Trúng: ${state.total.win_3s}\n`;
      }
      if (this.showTotal4Digits) {
        text += `Bốn số: ${state.total.money_4s} | Trúng: ${state.total.win_4s}\n`;
      }
      if (this.showTotalDd) {
        text += `Đầu đuôi: ${state.total.money_dd} | Trúng: ${state.total.win_dd}\n`;
      }
      if (this.showTotalXc) {
        text += `Xỉu chủ: ${state.total.money_xc} | Trúng: ${state.total.win_xc}\n`;
      }
      if (this.showTotalDt) {
        text += `Đá thẳng: ${state.total.money_dt} | Trúng: ${state.total.win_dt}\n`;
      }
      if (this.showTotalDc) {
        text += `Đá chéo: ${state.total.money_dc} | Trúng: ${state.total.win_dc}\n`;
      }
      const thuchiText = state.total.profit < 0 ? "Thu" : "Chi";
      const total = state.total.profit || state.total.commission;
      text += `Tổng ${thuchiText}:${total}\n`;
      return text;
    },

    dayOfWeek(state) {
      return moment(state.date).day() + 1;
    },
    provinceFilterChoices(state) {
      const choices = PROVINCE_TITLE[state.selectedRegion][moment(state.date).day() + 1];
      const res = [];
      Object.keys(choices).forEach((key) => {
        res.push({ name: choices[key], item: key });
      });
      return res;
    },
    numbertypeFilterChoices() {
      return Object.keys(SYNTAX_TITLE).map((key) => {
        return { name: SYNTAX_TITLE[key], item: key };
      });
    },
    filteredNumbers(state) {
      let res = state.reportNumbers.numbers;

      if (state.reportNumbers.selectedProvinces && state.reportNumbers.selectedProvinces.length > 0) {
        res = res.filter((x) => _.includes(state.reportNumbers.selectedProvinces, x.province_index));
      }

      if (state.reportNumbers.selectedNumbertype && state.reportNumbers.selectedNumbertype.length > 0) {
        res = res.filter((x) => {
          return _.includes(state.reportNumbers.selectedNumbertype, x.numbertypeid.toString());
        });
      }

      res = _.map(res, (x) => {
        if (x.numbertypeid != 8 && x.numbertypeid != 9) {
          if (x.number.length == 2 && x.money >= 500) {
            x["_rowVariant"] = "danger";
          }

          if (x.number.length > 2 && x.money >= 100) {
            x["_rowVariant"] = "danger";
          }
        }

        return x;
      });

      return res;
    },
  },
  actions: {
    init() {
      return customerService.fetch().then((res) => {
        const commonStore = useCommonStore();
        if (!_.isEmpty(res.data)) {
          this.customers = res.data;
          this.selectedCustomer = res.data[0].id;
        } else {
          commonStore.err("Chưa có thông tin khách hàng, xin hãy thêm thông tin khách hàng");
        }
      });
    },

    getList(action) {
      this.loading = true;
      this.resetState();

      const data = {
        customer_id: this.selectedCustomer,
        date: this.date,
        lottery_date: this.lotteryDate,
        region: this.selectedRegion,
        action,
      };
      const commonStore = useCommonStore();

      return messageService
        .getList(data)
        .then((res) => {
          if (!res.data.success) {
            this.error = res.data.errors[0];
            return;
          }

          if (_.isEmpty(res.data.data)) {
            this.error = "Không có tin nhắn để hiển thị";
            return;
          }

          const data = res.data.data;
          this.total = data.total;
          this.messages = data.messages;
        })
        .catch(() => commonStore.err("Có lỗi trong quá trình xử lý"))
        .finally(() => (this.loading = false));
    },

    getListNumbers() {
      this.loading = true;
      this.resetState();

      const data = {
        customer_id: this.selectedCustomer,
        date: this.date,
        lottery_date: this.lotteryDate,
        region: this.selectedRegion,
        action: 3,
      };

      const commonStore = useCommonStore();

      return messageService
        .getListNumbers(data)
        .then((res) => {
          if (!res.data.success) {
            this.error = res.data.errors[0];
            return;
          }

          if (_.isEmpty(res.data.data)) {
            this.error = "Không có tin nhắn để hiển thị";
            return;
          }

          const data = res.data.data;
          this.reportNumbers.numbers = data;
        })
        .catch(() => commonStore.err("Có lỗi trong quá trình xử lý"))
        .finally(() => (this.loading = false));
    },

    del(id) {
      const commonStore = useCommonStore();
      this.loading = true;
      return messageService
        .del(id)
        .then(() => (this.messages = _.remove(this.messages, (x) => x.id != id)))
        .catch(() => commonStore.err("Có lỗi trong quá trình xử lý"))
        .finally(() => (this.loading = false));
    },

    resetState() {
      this.error = "";
      this.total = {};
      this.messages = [];
      this.reportNumbers = {};
    },
  },
});
