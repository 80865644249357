import Quill from "quill";
import sanitizeHtml from "sanitize-html";

const Clipboard = Quill.import("modules/clipboard");
const Delta = Quill.import("delta");

// class VueClipBoard extends Clipboard {
//   onPaste(e) {
//     e.preventDefault();
//     const range = this.quill.getSelection();
//     const text = e.clipboardData.getData("text/plain");
//     const delta = new Delta().retain(range.index).delete(range.length).insert(text);
//     const index = text.length + range.index;
//     const length = 0;
//     this.quill.updateContents(delta, "user");
//     this.quill.setSelection(index, length, "user");
//     this.quill.scrollIntoView();
//   }
// }

class PlainClipboard extends Clipboard {
  convert(html = null) {
    if (typeof html === "string") {
      this.container.innerHTML = html;
    }

    let htmlText = this.container.innerHTML;
    htmlText = sanitizeHtml(htmlText);
    htmlText = htmlText.replaceAll("&nbsp;", " ");
    htmlText = htmlText.replaceAll("<div><br /></div>", "\n");
    htmlText = htmlText.replaceAll("<div><br/></div>", "\n");
    htmlText = htmlText.replaceAll("<div><br></div>", "\n");
    htmlText = htmlText.replaceAll("<p><br /></p>", "\n");
    htmlText = htmlText.replaceAll("<p><br/></p>", "\n");
    htmlText = htmlText.replaceAll("<p><br></p>", "\n");
    htmlText = htmlText.replaceAll("</div>", "\n");
    htmlText = htmlText.replaceAll("</p>", "\n");
    htmlText = htmlText.replaceAll("<br/>", "\n");
    htmlText = htmlText.replaceAll("<br />", "\n");
    htmlText = htmlText.replaceAll("<br>", "\n");
    htmlText = htmlText.replaceAll(/<[^>]+>/g, "");
    htmlText = htmlText.replaceAll(/\n+$/g, "\n");
    // htmlText = htmlText.replace(/[^\x00-\x7F]/g, "");

    this.container.innerHTML = "";
    return new Delta().insert(htmlText);
  }
}

export default PlainClipboard;
