import userService from "@/services/user.service";

export const manager3 = {
  namespaced: true,
  state: {
    users: [],
  },
  actions: {
    fetchUsers({ commit }) {
      commit("loading", true, { root: true });

      return userService
        .list()
        .then(
          (res) => {
            commit("fetchUsers", res.data.data);
            return Promise.resolve();
          },
          (error) => {
            console.log(error);
            return Promise.reject();
          }
        )
        .catch(() => commit("error", "Có lỗi trong quá trình xử lý", { root: true }))
        .finally(() => {
          commit("loading", false, { root: true });
        });
    },
    update({ commit, dispatch }, user) {
      commit("loading", true, { root: true });

      return userService
        .update(user)
        .then(
          () => {
            dispatch("success", "Update thành công", { root: true });
          },
          () => {
            dispatch("error", "Update thất bại", { root: true });
          }
        )
        .finally(() => {
          commit("loading", false, { root: true });
        });
    },
  },
  mutations: {
    fetchUsers(state, users) {
      state.users = users;
    },
  },
};
