import userService from "@/services/user.service";
import _ from "lodash";

export const manager = {
  namespaced: true,
  state: {
    users: [],
    selected: [],
    selectedUsername: "",
    errors: "",
  },

  getters: {
    fields: () => ["index", "selected", "username", "expired_date", "groupname"],
  },
  actions: {
    fetchUsers({ commit }) {
      commit("loading", true, { root: true });

      return userService
        .list()
        .then(
          (res) => {
            commit("fetchUsers", res.data.data);
            return Promise.resolve();
          },
          (error) => {
            console.log(error);
            return Promise.reject();
          }
        )
        .catch(() => commit("error", "Có lỗi trong quá trình xử lý", { root: true }))
        .finally(() => {
          commit("loading", false, { root: true });
        });
    },
    update({ commit, state }) {
      commit("loading", true, { root: true });

      const data = {
        users: state.selectedUsername,
      };

      return userService.extend(data).finally(() => {
        commit("loading", false, { root: true });
      });
    },
  },
  mutations: {
    fetchUsers(state, users) {
      state.users = users;
    },
    setSelected(state, users) {
      state.selected = users;

      const usernames = _.map(users, (user) => {
        return user.username;
      });

      state.selectedUsername = _.join(usernames, ",");
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
};
