<script>
export default {
  name: "MessageErrorComponent",
  components: {
    QuillEditor,
  },
};
</script>

<script setup>
import { useMessageErrorStore } from "@/stores/message.error.module";

// import Quill from "quill";

import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { QuillEditor } from "@vueup/vue-quill";
const store = useMessageErrorStore();

store.fetch();
</script>

<template>
  <b-container class="pt-3">
    <b-row class="justify-content-center justify-content-md-center">
      <b-cols><h1>Message Error</h1></b-cols>
    </b-row>
    <b-row v-for="(item, index) in store.$state.data" :key="index">
      <b-cols>
        <QuillEditor theme="snow" v-model:content="item.quillContent" contentType="delta" id="editor" />
      </b-cols>
    </b-row>
    <b-row>
      <b-cols>
        <b-button @click="store.fetch">Fetch</b-button>
      </b-cols>
    </b-row>
  </b-container>
</template>

<style></style>
