import userService from "@/services/user.service";

export const feedback = {
  namespaced: true,
  state: {
    fb_message_id: "",
    fb_content: "",
    loading: false,
  },

  getters: {},
  actions: {
    send({ commit, state }) {
      if (state.fb_content === "") {
        return Promise.reject("Nội dung không được để trống");
      }
      commit("setIsLoading", true);
      const data = {
        fb_message_id: state.fb_message_id,
        fb_content: state.fb_content,
      };
      return userService.feedback(data).finally(() => {
        commit("setIsLoading", false);
      });
    },
  },

  mutations: {
    setIsLoading(state, loading) {
      state.loading = loading;
    },
    setId(state, id) {
      state.fb_message_id = id;
    },
  },
};
