<script>
export default {
  name: "ReportComponent",
};
</script>

<script setup>
import { useReportStore } from "@/stores/report.module";
import { useCommonStore } from "@/stores/common.module";
import { region_text, type_text } from "@/utils/constants";
import { computed } from "vue";
import { getCurrentInstance } from "vue";
import ReportDetail from "@/components/ReportDetail.vue";
import { useClipboard } from "@vueuse/core";
// import { storeToRefs } from "pinia";

const reportStore = useReportStore();
const commonStore = useCommonStore();
const internalInstance = getCurrentInstance();
const { copy } = useClipboard({ legacy: true });

reportStore.init();

function report() {
  reportStore.$state.loading = true;

  reportStore
    .fetch()
    .catch(() => commonStore.err("Có lỗi trong quá trình xử lý"))
    .finally(() => (reportStore.$state.loading = false));
}

function toggleCheckAll() {
  reportStore.$state.selectedCustomers = reportStore.$state.checkAll
    ? reportStore.$state.customers.map((item) => item.value)
    : [];
}

const data = computed(() => reportStore.items);
// const { selectedItem } = storeToRefs(reportStore);
function generateTextToCopy(item) {
  let text = "";
  for (const key of ["mn", "mb", "mt", "total"]) {
    if (key in item.detail) {
      if (key != "total") {
        text += `${region_text[key]}: \n`;
      } else {
        text += "Tổng thu chi: \n";
      }

      for (const key_detail of ["2s", "3s", "4s", "dd", "xc", "dt", "dc"]) {
        if (
          item.detail[key][`money_${key_detail}`] != 0 ||
          item.detail[key][`win_${key_detail}`] != 0 ||
          item.detail[key][`profit_${key_detail}`] != 0
        ) {
          text += `${type_text[key_detail]}: ${item.detail[key][`money_${key_detail}`]} | Trúng: ${
            item.detail[key][`win_${key_detail}`]
          }\n`;
        }
      }

      const total = item.detail[key].profit || item.detail[key].commission;
      text += `Tổng ${total < 0 ? "Thu" : "Bù"}: ${total}\n\n\n`;

      if (item.customer_debt != 0 && item.customer_debt != null) {
        text += `Nợ cũ: ${item.customer_debt}\n`;
        text += `Tổng ${total < 0 ? "Thu" : "Bù"}: ${total + item.customer_debt}\n\n\n`;
      }
    }
  }

  copy(text).then(() => {
    commonStore.success("Đã sao chép");
  });
  // navigator.clipboard.writeText(text).then(() => {
  //   commonStore.success("Đã sao chép");
  // });
}

function rowClass(item, type) {
  if (!item || type !== "row") return;
  if (item.status === "minus") return "minus";
}

function showModal(item) {
  // const { selectedItem } = storeToRefs(reportStore);
  reportStore.$state.selectedToShowDetailCustomerId = item.customer_id;
  // console.log(reportStore.);

  // console.log(selectedItem.value);
  console.log(item);

  const bvModal = internalInstance.proxy._bv__modal;
  bvModal.show("modal-1");
}

function hideModal() {
  const bvModal = internalInstance.proxy._bv__modal;
  bvModal.hide("modal-1");
}

function handleCustomerDebtBtnClick(customerId) {
  if (reportStore.$state.customersDebt[customerId].enable) {
    reportStore.updateCustomerDebt(customerId);
  }

  reportStore.$state.customersDebt[customerId].enable = !reportStore.$state.customersDebt[customerId].enable;
}

function toggleIsTableLayout() {
  reportStore.updateIsCardLayout();
}
</script>

<template>
  <b-container>
    <b-row class="justify-content-center justify-content-md-center">
      <b-col cols="6" md="5">
        <h1>Thống kê</h1>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-col>
        <b-form-group label="Ngày bắt đầu" v-if="!reportStore.$state.findWinningNumber">
          <b-form-datepicker v-model="reportStore.$state.from"></b-form-datepicker>
        </b-form-group>
        <b-form-group label="Ngày dò số" v-if="reportStore.$state.findWinningNumber">
          <b-form-datepicker v-model="reportStore.$state.from"></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!reportStore.$state.findWinningNumber">
      <b-col>
        <b-form-group label="Ngày kết thúc">
          <b-form-datepicker v-model="reportStore.$state.to"></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-form-checkbox v-model="reportStore.$state.findWinningNumber"> Dò số và thống kê theo ngày </b-form-checkbox>
    </b-row>
    <br />
    <b-row>
      <b-form-group label="Khách hàng" v-slot="{ ariaDescribedby }">
        <b-form-checkbox v-model="reportStore.$state.checkAll" @change="toggleCheckAll">
          Chọn hết tất cả
        </b-form-checkbox>
        <br />
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="reportStore.$state.selectedCustomers"
          :options="reportStore.$state.customers"
          :aria-describedby="ariaDescribedby"
        ></b-form-checkbox-group>
      </b-form-group>
    </b-row>
    <b-row class="pt-3">
      <b-col>
        <b-form-group label="" for="btn">
          <b-button id="btn" variant="primary" block @click.prevent="report">
            {{ reportStore.$state.findWinningNumber ? "Dò số và thống kê" : "Thống kê" }}
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="pt-3" v-if="$isMobile()">
      <b-col>
        <b-form-group label="" for="btn">
          <b-form-checkbox
            v-model="reportStore.$state.isCardLayout"
            name="check-button"
            switch
            @change="toggleIsTableLayout(this)"
          >
            <b>Giao diện điện thoại </b>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="pt-5" v-if="reportStore.$state.showError">
      <b-col cols="12" md="6" class="mx-auto" style="color: red">
        <h3 style="text-align: center">Không có dữ liệu để thống kê</h3>
      </b-col>
    </b-row>

    <div class="pt-5" v-if="data.items.length > 0">
      <div v-if="$isMobile() && reportStore.$state.isCardLayout">
        <template v-for="item in data.items" :key="item.customer_id">
          <b-card class="mb-2" :header-bg-variant="item.customer_id != null ? '' : 'info'">
            <template #header>
              <div style="text-align: center">
                <b-button size="sm" variant="link" @click="showModal(item)" block>
                  {{ item.customer }}
                </b-button>
              </div>
            </template>
            <b-card-text>Miền nam: {{ item.mn }}</b-card-text>
            <b-card-text>Miền trung: {{ item.mt }}</b-card-text>
            <b-card-text>Miền bắc: {{ item.mb }}</b-card-text>
            <b-card-text>Tổng: {{ item.total }}</b-card-text>
            <b-card-text>Tổng dư nợ: {{ item.total_debt }}</b-card-text>
            <div class="d-flex align-items-center mb-2" v-if="item.customer_id != 0">
              <b-form-input
                v-model="reportStore.$state.customersDebt[item.customer_id].customer_debt"
                :disabled="!reportStore.$state.customersDebt[item.customer_id].enable"
                type="number"
                style="max-width: 100px"
              ></b-form-input>
              <b-button
                size="sm"
                @click="handleCustomerDebtBtnClick(item.customer_id)"
                variant="primary"
                style="margin-left: 5px"
              >
                {{ reportStore.$state.customersDebt[item.customer_id].enable ? "Lưu" : "Sửa" }}
              </b-button>
            </div>
            <b-button size="sm" @click="generateTextToCopy(item)" style="margin-right: 10px" block> Copy </b-button>
          </b-card>
        </template>
      </div>
      <div v-else>
        <b-table bordered hover :items="data.items" :fields="data.fields" :tbody-tr-class="rowClass">
          <template #cell(action)="row">
            <b-button size="sm" @click="generateTextToCopy(row.item)" style="margin-right: 10px"> Copy </b-button>
            <!-- <b-button size="sm" @click="row.toggleDetails"> {{ row.detailsShowing ? "Ẩn" : "Hiện" }} chi tiết </b-button> -->
          </template>
          <template #cell(customer)="row">
            <div style="text-align: center">
              <b-button size="sm" variant="link" @click="showModal(row.item)"> {{ row.item.customer }} </b-button>
            </div>
          </template>
          <template #cell(customer_debt)="row">
            <div class="d-flex justify-content-between align-items-center" v-if="row.item.customer_id != 0">
              <b-form-input
                v-model="reportStore.$state.customersDebt[row.item.customer_id].customer_debt"
                :disabled="!reportStore.$state.customersDebt[row.item.customer_id].enable"
                type="number"
                style="min-width: 100px"
              ></b-form-input>
              <b-button
                size="sm"
                @click="handleCustomerDebtBtnClick(row.item.customer_id)"
                variant="primary"
                style="margin-left: 5px"
              >
                {{ reportStore.$state.customersDebt[row.item.customer_id].enable ? "Lưu" : "Sửa" }}
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal id="modal-1">
      <report-detail
        :detail="reportStore.selectedItem.detail.mn"
        region="Miền Nam"
        v-if="reportStore.selectedItem.detail.mn != null"
      />
      <report-detail
        :detail="reportStore.selectedItem.detail.mt"
        region="Miền Trung"
        v-if="reportStore.selectedItem.detail.mt != null"
      />
      <report-detail
        :detail="reportStore.selectedItem.detail.mb"
        region="Miền Bắc"
        v-if="reportStore.selectedItem.detail.mb != null"
      />
      <template #modal-footer>
        <div class="w-100">
          <b-button class="mt-3" block @click="hideModal">Đóng</b-button>
        </div>
      </template>
      <template #modal-title>{{ reportStore.selectedItem.customer }} </template>
    </b-modal>
  </b-container>
</template>

<style>
.minus {
  color: red;
}
.b-table tbody tr:last-child {
  color: black !important;
  font-weight: bold !important;
}

.b-table tbody tr td:nth-child(6) {
  min-width: 190px;
}
</style>
