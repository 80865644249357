<script>
export default {
  name: "Syntax2Component",
};
</script>

<script setup>
import { useSyntaxStore } from "@/stores/syntax.module";
import { useCommonStore } from "@/stores/common.module";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { SYNTAX_TITLE } from "@/utils/constants";
import _ from "lodash";

const commonStore = useCommonStore();
const syntaxStore = useSyntaxStore();

syntaxStore.$state.loading = true;
syntaxStore
  .fetch()
  .catch((err) => {
    console.log(err);
    commonStore.err("Có lỗi trong quá trình xử lý");
  })
  .finally(() => {
    syntaxStore.$state.loading = false;
  });
</script>

<template>
  <b-container>
    <b-row class="justify-content-center justify-content-md-center">
      <h1>Cài đặt cú pháp kiểu đánh</h1>
    </b-row>

    <b-row class="justify-content-md-center">
      <b-col cols="12">
        <loading-spinner v-if="syntaxStore.$state.loading" />
        <template v-else>
          <template v-for="(title, key) in SYNTAX_TITLE" :key="key">
            <b-form-group
              id="fieldset-1"
              :label="title"
              :label-for="key"
              label-cols-sm="4"
              label-cols-lg="3"
              content-cols-sm="8"
              content-cols-lg="9"
              :invalid-feedback="_.get(syntaxStore.$state.errors, [key])"
              :state="_.isEmpty(_.get(syntaxStore.$state.errors, [key])) ? null : false"
            >
              <b-form-input
                :id="key"
                v-model="syntaxStore.displayNumbertypes[key]"
                :state="_.isEmpty(_.get(syntaxStore.$state.errors, [key])) ? null : false"
                trim
                @change="syntaxStore.onChangeNumbertypes(key)"
              ></b-form-input>
            </b-form-group>
          </template>

          <b-button block variant="primary" class="mb-5" @click="syntaxStore.saveNumbertypes">
            <b-spinner label="Spinning" v-if="syntaxStore.$state.posting"></b-spinner>
            <span v-else>Lưu</span>
          </b-button>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>
