import settingsService from "@/services/settings.service";
import _ from "lodash";

export const settings = {
  namespaced: true,
  state: {
    settings: [],
    isLoading: false,
  },

  getters: {
    isSelected: (state) => (id) => {
      return _.find(state.settings, (x) => x.id == id).userSettings?.setting_value == 1;
    },
  },
  actions: {
    fetchSettings({ commit }) {
      commit("loading", true, { root: true });

      return settingsService
        .getSettings()
        .then(
          (res) => {
            commit("fetchSettings", res.data.data);
            return Promise.resolve();
          },
          (error) => {
            console.log(error);
            return Promise.reject();
          }
        )
        .finally(() => {
          commit("loading", false, { root: true });
        });
    },

    update({ state }) {
      let userSettings = _.map(state.settings, (x) => x.userSettings);

      return settingsService.updateSettings(userSettings);
    },
  },
  mutations: {
    fetchSettings(state, settings) {
      state.settings = settings;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
};
