import client from "./http-common";

class SettingsService {
  getSettings() {
    return client.get("settings");
  }

  updateSettings(userSettings) {
    return client.post("settings/create2", { userSettings: userSettings });
  }
}

export default new SettingsService();
