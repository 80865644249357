<script>
export default {
  name: "FeedbackComponent",
};
</script>

<script setup>
// import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const state = store.state.feedback;
const route = useRoute();

if (route.params.id) {
  store.commit("feedback/setId", route.params.id);
}

function send() {
  store
    .dispatch("feedback/send")
    .then(() => store.dispatch("success", "Gửi thành công"))
    .catch((error) => store.dispatch("error", error));
}
</script>

<template>
  <b-container class="pt-5">
    <b-row class="justify-content-center justify-content-md-center">
      <b-cols><h1>Hỗ trợ kỹ thuật</h1></b-cols>
    </b-row>
    <b-row class="pb-5">
      <b-col>
        <h3>Liên hệ zalo: 0937886654</h3>
        <h3>Liên hệ telegram: <a href="https://t.me/+84937886654" target="_blank">0937886654</a></h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group id="username-group" label="Nội dung:" label-for="password">
          <b-form-textarea id="content" required rows="10" v-model="state.fb_content"></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4" offset-md="4" class="pb-2">
        <b-button type="submit" variant="primary" block :disabled="state.loading" @click.prevent="send">
          <span v-show="state.loading" class="spinner-border spinner-border-sm" />
          <span>Gửi</span>
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<style></style>
